<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div class="d-flex flex-column create-comment-form" *ngIf="!loading">
    <app-error-message [errorMessage]="errorMessage"></app-error-message>
    <div class="row flex-grow-1">
      <div class="col-sm-12 mb-1">
        <app-transcribe (transcriptionResult)="onTextReceived($event)" *appPermissionRequired="'Clinical.Transcribe.Write'"></app-transcribe>
      </div>
      <mat-divider></mat-divider>
      <div class="col-sm-12 mb-1 text-end">
        <div class="d-flex flex-row align-items-start justify-content-between">
          <mat-list *ngIf="currentComment.uploads.length" class="py-0">
            @for (upload of currentComment.uploads; track upload.id){
              <mat-list-item class="text-start">
                <span (click)="appFileDownloader.openAttachment()">{{ upload.fileName }}</span>
                <div matListItemMeta class="mx-0">
                  <button mat-icon-button appConfirmationDialog [confirmMessage]="'Are you sure you want to delete this upload?'" (confirm)="appFileEditor.deleteAttachment()" [disabled]="appFileDownloader.processing | async" *appPermissionRequired="'Clinical.Uploads.Delete'"><mat-icon>delete</mat-icon></button>
                </div>
                <app-file-downloader #appFileDownloader="appFileDownloader" [upload]="upload"></app-file-downloader>
                <app-file-editor #appFileEditor="appFileEditor" [upload]="upload"></app-file-editor>
              </mat-list-item>
            }
          </mat-list>
          <app-file-upload-button class="mt-2" *appPermissionRequired="'Clinical.Uploads.Write'; condition: hasPatientDetails" (complete)="onUploadComplete($event)" [patientId]="patient.id" [commentId]="currentComment.id" [tags]="tags" [type]="'patient'">Add Uploads</app-file-upload-button>
        </div>
      </div>
      <div class="col-sm-12 mb-1">
        <textarea appTextareaAutosize appTextareaJumptext
                  [(ngModel)]="currentComment.comment.text" (ngModelChange)="onModelChange($event)"
                  [jumpTextTarget]="currentComment.commentTemplate.commentTemplate.jumpTarget" [rows]="13"
                  [style.background-color]="currentComment.commentType.color" class="draft-custom-form-control jump-text-container" >
        </textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 d-flex flex-row justify-content-between align-items-center">
        <span class="mat-card-subtitle">{{currentComment.lastModifiedDate | date:'yyyy-MM-dd HH:mm'}}</span>
        <span class="mat-card-subtitle">{{currentComment.lastModifiedUser.firstName}} {{currentComment.lastModifiedUser.surname}}</span>
      </div>
    </div>
</div>
