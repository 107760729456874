import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MedicationType} from '../../../medication-repository/entities/medication-type';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MedicationRepositoryService} from '../../../medication-repository/medication-repository.service';
import {Subscription} from 'rxjs';

export interface MedicationTypeFormDialogData {
  medicationType: MedicationType;
}
@Component({
  selector: 'app-types-form',
  templateUrl: './types-form.component.html',
  styleUrls: ['./types-form.component.scss']
})
export class TypesFormComponent implements OnInit, OnDestroy {
  constructor(private dialogRef: MatDialogRef<TypesFormComponent>, @Inject(MAT_DIALOG_DATA) private data: MedicationTypeFormDialogData, private formBuilder: UntypedFormBuilder, private medicationRepository: MedicationRepositoryService) { }
  private repositorySubscription: Subscription;
  validate = false;
  editing = false;
  form: UntypedFormGroup;

  ngOnInit(): void {
    this.editing = this.data?.medicationType !== undefined;
    this.form = this.formBuilder.group({
      id: [null],
      description: ['', Validators.required],
      comment: ['']
    });
    if (this.editing) {
      this.form.patchValue(this.data.medicationType);
      this.form.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    if (this.repositorySubscription) { this.repositorySubscription.unsubscribe(); }
  }

  save() {
    if (this.editing) {
      this.update();
    } else {
      this.create();
    }
  }

  private update() {
    if (this.form.valid) {
      const item = this.formToMedicationType();
      this.repositorySubscription = this.medicationRepository.adminUpdateMedicationType(item.id, item).subscribe(result => {
        this.dialogRef.close(item);
      });
    }
    this.validate = true;
  }

  private create() {
    if (this.form.valid) {
      const item = this.formToMedicationType();
      this.repositorySubscription = this.medicationRepository.adminCreateMedicationType(item).subscribe(result => {
        this.dialogRef.close(item);
      });
    }
    this.validate = true;
  }

  close() {
    this.dialogRef.close();
  }
  private formToMedicationType(): MedicationType {
    return this.form.value as MedicationType;
  }
}
