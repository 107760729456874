import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ReportControl} from '../../report-builder/entities/report-control';
import {ReportControlEditorService} from '../report-control-editor.service';
import {Medication} from '../../medication-repository/entities/medication';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {Problem} from '../../problems-repository/entities/problem';
import {TreatmentPlanItem} from '../../treatment-plans-repository/entities/treatment-plan-item';
import {MedicationRepositoryService} from '../../medication-repository/medication-repository.service';
import {PatientService} from '../../patients/services/patient.service';
import {PatientEventsService} from '../../patients/services/patient-events.service';

@Component({
  selector: 'app-medication-list-renderer',
  templateUrl: './medication-list-renderer.component.html',
  styleUrls: ['./medication-list-renderer.component.scss']
})
export class MedicationListRendererComponent implements OnInit, OnDestroy {

  @Input() patientId: number;
  @Input() control: ReportControl;
  medicationList:  Medication[] = [];
  private repositorySubscription: Subscription;
  private eventsSubscription: Subscription;

  constructor(private medicationRepository: MedicationRepositoryService, private patientEventsService: PatientEventsService, private editorService: ReportControlEditorService) {
  }

  ngOnInit(): void {
    this.medicationList = this.control.medicationListValue;
    this.loadMedicationList();
    this.eventsSubscription = this.patientEventsService.events$.pipe(filter(e => e.type === 'medication_item_added' || e.type === 'medication_item_deleted')).subscribe(() => this.loadMedicationList());
  }

  ngOnDestroy(): void {
    this.repositorySubscription?.unsubscribe();
    this.eventsSubscription?.unsubscribe();
  }

  loadMedicationList(): void {
    this.repositorySubscription = this.medicationRepository.loadPatientMedication(this.patientId).subscribe(medication => {
      this.medicationList = medication.sort((a, b) => a.createdDate > b.createdDate ? 1 : -1);
      this.control.medicationListValue = this.medicationList;
      this.editorService.triggerEvent();
    });
  }
}
