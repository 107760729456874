import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FileUploadEventsService {

    private uploadBehaviourSubject: Subject<UploadProgressEvent> = new Subject();
    uploadEvents$ = this.uploadBehaviourSubject.asObservable();

    constructor() { }

    uploadProgress(fileName: string, progress: number) {
        this.uploadBehaviourSubject.next(new UploadProgressEvent('progress', fileName, progress));
    }

    uploadComplete(fileName: string) {
        this.uploadBehaviourSubject.next(new UploadProgressEvent('complete', fileName, 100));
    }

    uploadFailed(fileName: string, error: any) {
        this.uploadBehaviourSubject.next(new UploadProgressEvent('error', fileName, 0, error));
    }
}

export class UploadProgressEvent {
    constructor(readonly type: UploadProgressEventType, readonly fileName: string, readonly progress: number, readonly error?: string) { }
}

export type UploadProgressEventType =
    | 'initialized'
    | 'error'
    | 'complete'
    | 'progress';
