<mat-card appearance="outlined" class="compact mb-3" [formGroup]="formGroup">
  <mat-card-content>
    <mat-card-title class="d-flex align-items-center justify-content-between">
      {{controlFormGroup.get('name').value}}
      <button mat-icon-button (click)="deleteItem()">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-card-title>
    <div class="row">
      <div class="col-sm-12">
        Practice Number:&nbsp;<strong>{{controlFormGroup.get('practiceNumber').value}}</strong>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        Tel:&nbsp;<strong>{{controlFormGroup.get('tel').value}}</strong>
      </div>
      <div class="col-sm-12 col-md-6">
        Email:&nbsp;<strong>{{controlFormGroup.get('email').value}}</strong>
      </div>
      <div class="col-sm-12 col-md-6">
        Fax:&nbsp;<strong>{{controlFormGroup.get('fax').value}}</strong>
      </div>
    </div>
  </mat-card-content>
</mat-card>
