<app-loading-bar *ngIf="loading || saving"></app-loading-bar>
<form class="form d-flex flex-column h-100" [formGroup]="form" *ngIf="!loading">
  <input type="hidden" formControlName="patientName"/>
  <div class="row">
    <div class="col-12 d-flex flex-row">
      <app-mat-autocomplete class="flex-grow-1 me-3" placeholder="Search Medication" [searchObservable]="searchMedication$" (addNewItem)='onAddNewItem()'
                              [displayResultsCardTemplate]='displayResultsCardTemplate' [addItemCardText]="'Click here to add a new medication item.'" *appPermissionRequired="'Clinical.CustomScripts.Write'"></app-mat-autocomplete>
      <ng-template #displayResultsCardTemplate let-result="result" let-queryText="queryText">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <span [innerHtml]="result.description + ' ' + result.dosage + ' ' + result.diagnosisCode | textHighlight: queryText"></span>
          <button mat-icon-button (click)="onItemSelected(result)"><mat-icon>add</mat-icon></button>
        </div>
        <mat-form-field color="primary" appearance="fill" class="compact w-100">
          <mat-label>Comments/Notes</mat-label>
          <input matInput type="text" [(ngModel)]="result.comment" [ngModelOptions]="{standalone: true}" />
        </mat-form-field>
      </ng-template>

      <mat-form-field appearance="fill" color="primary">
        <mat-label>Script Date</mat-label>
        <app-mat-date-time-input formControlName="scriptDate" [enableTime]="false" #dateTimeInputComponent></app-mat-date-time-input>
        <button mat-icon-button matSuffix (click)="dateTimeInputComponent.togglePicker()"><mat-icon>today</mat-icon></button>
      </mat-form-field>
    </div>
  </div>
  <div class="row flex-grow-1">
    <div class="col-12">
      <div class="mat-list mat-list-dynamic">
        <ng-container  *ngFor="let item of medication; let i = index;">
          <div class="pb-3">
            <div class="row">
              <div class="col-12">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <span class="flex-grow-1">{{item.description}}</span>
                  <mat-form-field color="primary" class="me-3 compact">
                    <mat-label>Dosage</mat-label>
                    <input matInput type="text" [(ngModel)]="item.dosage" [ngModelOptions]="{standalone: true, updateOn: 'blur'}" (ngModelChange)="medicationItemChanged()" />
                  </mat-form-field>
                  <mat-form-field color="primary" class="compact">
                    <mat-label>Diagnosis Code</mat-label>
                    <input matInput type="text" [(ngModel)]="item.diagnosisCode" [ngModelOptions]="{standalone: true, updateOn: 'blur'}" (ngModelChange)="medicationItemChanged()" />
                  </mat-form-field>
                  <button mat-icon-button (click)="removeMedicationFromScript(i)"><mat-icon>delete</mat-icon></button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-form-field color="primary" class="w-100 compact py-2">
                  <mat-label>Comment</mat-label>
                  <textarea matInput placeholder="Comments/Notes" [(ngModel)]="item.comment" [ngModelOptions]="{standalone: true, updateOn: 'blur'}" [rows]="1" (ngModelChange)="medicationItemChanged()"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
        </ng-container>
        <div class="py-3" *ngIf="!hasMedication">
          <span>No medication has been added to this script.</span>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-spacer"></div>
  <div class="row" *ngIf="isEditing">
    <div class="col-sm-12 d-flex flex-row justify-content-between align-items-center">
      <span class="mat-card-subtitle">{{script.lastModifiedDate | date:'yyyy-MM-dd HH:mm'}}</span>
      <span class="mat-card-subtitle">{{script.lastModifiedUser.firstName}} {{script.lastModifiedUser.surname}}</span>
    </div>
  </div>
</form>
