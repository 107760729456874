import {Injectable} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators} from 'src/app/shared/validators/custom-validators';
import {AddressDetail, ContactNumber, EmailAddress, Patient, SecondaryContact, Doctor} from '../../patient-repository/entities/patient';
import {CoreService} from '../../../core/services/core.service';

@Injectable({
  providedIn: 'root'
})
export class PatientFormService {

  private patientForm: UntypedFormGroup = null;

  constructor(private formBuilder: UntypedFormBuilder, private coreService: CoreService) {
  }

  get form(): UntypedFormGroup {
    return this.patientForm;
  }

  get hasScheme(): boolean {
    return this.patientForm.get('medicalAid').get('scheme').value !== '';
  }

  private get addressDetails(): UntypedFormArray {
    return this.patientForm.get('addressDetails') as UntypedFormArray;
  }

  private get emailAddresses(): UntypedFormArray {
    return this.patientForm.get('emailAddresses') as UntypedFormArray;
  }

  private get contactNumbers(): UntypedFormArray {
    return this.patientForm.get('contactNumbers') as UntypedFormArray;
  }

  private get secondaryContacts(): UntypedFormArray {
    return this.patientForm.get('secondaryContacts') as UntypedFormArray;
  }

  private get familyDoctors(): UntypedFormArray {
    return this.patientForm.get('familyDoctors') as UntypedFormArray;
  }

  private get referringDoctors(): UntypedFormArray {
    return this.patientForm.get('referringDoctors') as UntypedFormArray;
  }

  private get billingContact(): UntypedFormGroup {
    return this.patientForm.get('billingContact') as UntypedFormGroup;
  }

  private get billingContactContactNumbers(): UntypedFormArray {
    return this.billingContact.get('billingContactContactNumbers') as UntypedFormArray;
  }

  private get billingContactEmailAddresses(): UntypedFormArray {
    return this.billingContact.get('billingContactEmailAddresses') as UntypedFormArray;
  }

  private get billingContactAddressDetails(): UntypedFormArray {
    return this.billingContact.get('billingContactAddressDetails') as UntypedFormArray;
  }

  reset() {
    this.patientForm = null;
  }

  new(patient: Patient): UntypedFormGroup {
    this.patientForm = this.newForm();
    if (patient) {
      this.setData(patient);
    }
    return this.form;
  }

  addAddress() {
    this.addressDetails.push(this.newAddressFormGroup());
  }

  removeAddress(index: number) {
    this.addressDetails.removeAt(index);
  }

  addEmailAddress() {
    this.emailAddresses.push(this.newEmailAddressFormGroup());
  }

  removeEmailAddress(index: number) {
    this.emailAddresses.removeAt(index);
  }

  addContactNumber() {
    this.contactNumbers.push(this.newContactNumberFormGroup());
  }

  removeContactNumber(index: number) {
    this.contactNumbers.removeAt(index);
  }

  addSecondaryContactPerson() {
    this.secondaryContacts.push(this.newSecondaryContactFormGroup());
  }

  removeSecondaryContactPerson(index: number) {
    this.secondaryContacts.removeAt(index);
  }

  addFamilyDoctor(doctor: Doctor) {
    this.familyDoctors.push(this.newDoctorFormGroup(doctor));
  }

  addReferringDoctor(doctor: Doctor) {
    this.referringDoctors.push(this.newDoctorFormGroup(doctor));
  }

  removeFamilyDoctor(index: number) {
    this.familyDoctors.removeAt(index);

  }

  removeReferringDoctor(index: number) {
    this.referringDoctors.removeAt(index);

  }

  addBillingContactAddress() {
    this.billingContactAddressDetails.push(this.newAddressFormGroup());

  }

  removeBillingContactAddress(index: number) {
    this.billingContactAddressDetails.removeAt(index);

  }

  addBillingContactEmailAddress() {
    this.billingContactEmailAddresses.push(this.newEmailAddressFormGroup());

  }

  removeBillingContactEmailAddress(index: number) {
    this.billingContactEmailAddresses.removeAt(index);

  }

  addBillingContactContactNumber() {
    this.billingContactContactNumbers.push(this.newContactNumberFormGroup());

  }

  removeBillingContactContactNumber(index: number) {
    this.billingContactContactNumbers.removeAt(index);

  }

  addSecondaryContactContactNumber(contactIndex: number) {
    (this.secondaryContacts.controls[contactIndex].get('contactNumbers') as UntypedFormArray).push(this.newContactNumberFormGroup());

  }

  removeSecondaryContactContactNumber(contactIndex: number, index: number) {
    (this.secondaryContacts.controls[contactIndex].get('contactNumbers') as UntypedFormArray).removeAt(index);

  }

  validateAllFormFields() {
    return CustomValidators.validateAllFormFields(this.form);
  }

  printAllFormErrors(): any[] {
    return CustomValidators.printAllFormErrors(this.form);
  }

  private newAddressFormGroup(address?: AddressDetail): UntypedFormGroup {
    if (address) {
      return this.formBuilder.group({
        type: [address.type || 2, Validators.required],
        label: [address.label || 'Physical', Validators.required],
        address: [address.address || '']
      });
    }
    return this.formBuilder.group({
      type: [2, Validators.required],
      label: ['Physical', Validators.required],
      address: ['']
    });
  }

  private newEmailAddressFormGroup(emailAddress?: EmailAddress): UntypedFormGroup {
    if (emailAddress) {
      return this.formBuilder.group({
        type: [emailAddress.type || 0, Validators.required],
        label: [emailAddress.description || 'Home', Validators.required],
        emailAddress: [emailAddress.emailAddress || '', [Validators.email]],
        emailAddressConfirm: [emailAddress.emailAddress || ''],
      }, { validators: [CustomValidators.duplicateFieldMatchRequired('emailAddress', 'emailAddressConfirm')] });
    }
    return this.formBuilder.group({
      type: [0, Validators.required],
      label: ['Home', Validators.required],
      emailAddress: ['', [Validators.email]],
      emailAddressConfirm: [''],
    }, { validators: [CustomValidators.duplicateFieldMatchRequired('emailAddress', 'emailAddressConfirm')] });
  }

  private newContactNumberFormGroup(contactNumber?: ContactNumber, required: boolean = false): UntypedFormGroup {
    let group: UntypedFormGroup;
    if (contactNumber) {
      group = this.formBuilder.group({
        type: [contactNumber.type || 2, Validators.required],
        label: [contactNumber.description || 'Cell', Validators.required],
        number: [contactNumber.number || '', [Validators.pattern('[0-9 ]*'), Validators.minLength(10)]],
      });
    } else {
      group = this.formBuilder.group({
        type: [2, Validators.required],
        label: ['Cell', Validators.required],
        number: ['', [Validators.pattern('[0-9 ]*'), Validators.minLength(10)]],
      });
    }
    if (required) {
      (group.get('number') as UntypedFormControl).addValidators(Validators.required);
    }
    return group;
  }

  private newSecondaryContactFormGroup(contactPerson?: SecondaryContact): UntypedFormGroup {
    if (contactPerson) {
      const contactFormGroup = this.formBuilder.group({
        firstName: [contactPerson.firstName || ''],
        surname: [contactPerson.surname || ''],
        relationToPatient: [contactPerson.relation || ''],
        contactNumbers: this.formBuilder.array([]),
      });
      if (contactPerson.contactNumbers) {
        contactPerson.contactNumbers.forEach(contact => {
          (contactFormGroup.get('contactNumbers') as UntypedFormArray).push(this.newContactNumberFormGroup(contact));
        });
      } else {
        (contactFormGroup.get('contactNumbers') as UntypedFormArray).push(this.newContactNumberFormGroup());
      }
      return contactFormGroup;
    }
    return this.formBuilder.group({
      firstName: [''],
      surname: [''],
      relationToPatient: [''],
      contactNumbers: this.formBuilder.array([this.newContactNumberFormGroup()]),
    });
  }

  private newDoctorFormGroup(doctor: Doctor): UntypedFormGroup {
    return this.formBuilder.group({
      name: [doctor.name || ''],
      practiceNumber: [doctor.practiceNumber || ''],
      tel: [doctor.tel || ''],
      fax: [doctor.fax || ''],
      email: [doctor.email || ''],
      preferredGreeting: [doctor.preferredGreeting || ''],
      preferredContactMethod: [doctor.preferredContactMethod || '']
    });
  }

  private newForm(): UntypedFormGroup {
    const form =  this.formBuilder.group({
      id: [''],
      status: [null],
      title: ['', Validators.required],
      firstName: ['', Validators.required],
      surname: ['', Validators.required],
      middleNames: [''],
      nickname: [''],
      identityNumber: ['', [CustomValidators.identityNumberValidator(true)]],
      identityNumberConfirmation: [''],
      dateOfBirth: ['', Validators.required],
      gender: [''],
      passportNumber: [''],
      passportNumberConfirmation: [''],
      homeLanguage: [''],
      race: [''],
      emailAddresses: this.formBuilder.array([this.newEmailAddressFormGroup()]),
      contactNumbers: this.formBuilder.array([this.newContactNumberFormGroup()]),
      addressDetails: this.formBuilder.array([this.newAddressFormGroup()]),
      familyDoctors: this.formBuilder.array([]),
      referringDoctors: this.formBuilder.array([]),
      secondaryContacts: this.formBuilder.array([this.newSecondaryContactFormGroup()]),
      billingContact: this.formBuilder.group({
        billingContactFirstName: ['', Validators.required],
        billingContactSurname: ['', Validators.required],
        billingContactIdentityNumber: ['', [CustomValidators.identityNumberValidator(true)]],
        billingContactIdentityNumberConfirmation: [''],
        billingContactContactNumbers: this.formBuilder.array([this.newContactNumberFormGroup()]),
        billingContactAddressDetails: this.formBuilder.array([this.newAddressFormGroup()]),
        billingContactEmailAddresses: this.formBuilder.array([this.newEmailAddressFormGroup()]),
      }, {validators: [CustomValidators.duplicateFieldMatchRequired('billingContactIdentityNumber', 'billingContactIdentityNumberConfirmation')]}),
      medicalAid: this.formBuilder.group({
        scheme: [''],
        destinationCode: [''],
        medicalAidNumber: [''],
        dependantCode: [''],
        mainMembersFirstName: [''],
        mainMembersSurname: [''],
      }),
      fileNumber: [''],
      profilePictureUrl: [''],
    }, {validators: [CustomValidators.duplicateFieldMatchRequired('identityNumber', 'identityNumberConfirmation'),
      CustomValidators.duplicateFieldMatchRequired('passportNumber', 'passportNumberConfirmation')]});
    if (!this.coreService.practiceUser.partialPatientFormValidationEnabled) {
      form.addValidators([CustomValidators.atLeastOneValueRequired({
        formArrayName: 'contactNumbers', formControlName: 'number'},
        {formArrayName: 'addressDetails', formControlName: 'address'},
        {formArrayName: 'emailAddresses', formControlName: 'emailAddress'})]);
      form.get('billingContact').addValidators([CustomValidators.atLeastOneValueRequired({
        formArrayName: 'billingContactContactNumbers', formControlName: 'number'},
        {formArrayName: 'billingContactAddressDetails', formControlName: 'address'},
        {formArrayName: 'billingContactEmailAddresses', formControlName: 'emailAddress'})]);
    }
    return form;
  }

  private setData(patient: Patient) {
    if (!patient) { return; }
    this.patientForm.patchValue({
      id: patient.id,
      status: patient.status.id,
      title: patient.title,
      firstName: patient.firstName,
      surname: patient.surname,
      middleNames: patient.middleNames,
      nickname: patient.nickname,
      identityNumber: patient.identityNumber,
      identityNumberConfirmation: patient.identityNumber,
      dateOfBirth: patient.dateOfBirth,
      gender: patient.gender,
      passportNumber: patient.passportNumber,
      passportNumberConfirmation: patient.passportNumber,
      homeLanguage: patient.homeLanguage,
      race: patient.race,
      fileNumber: patient.fileNumber,
      profilePictureUrl: patient.profilePictureUrl,
    });
    if (patient.billingContact) {
      this.patientForm.get('billingContact').patchValue({
        billingContactFirstName: patient.billingContact.billingContactFirstName,
        billingContactSurname: patient.billingContact.billingContactSurname,
        billingContactIdentityNumber: patient.billingContact.billingContactIdentityNumber,
        billingContactIdentityNumberConfirmation: patient.billingContact.billingContactIdentityNumber,
      });
    }
    if (patient.medicalAid) {
      this.patientForm.get('medicalAid').patchValue({
        scheme: patient.medicalAid.scheme,
        destinationCode: patient.medicalAid.destinationCode,
        medicalAidNumber: patient.medicalAid.medicalAidNumber,
        dependantCode: patient.medicalAid.dependantCode,
        mainMembersFirstName: patient.medicalAid.mainMembersFirstName,
        mainMembersSurname: patient.medicalAid.mainMembersSurname,
      });
    }
    if (patient.familyDoctors) {
      (this.patientForm.get('familyDoctors') as UntypedFormArray).clear();
      patient.familyDoctors.forEach(doctor => {
        (this.patientForm.get('familyDoctors') as UntypedFormArray).push(this.newDoctorFormGroup(doctor));
      });
    }
    if (patient.referringDoctors?.length > 0) {
      (this.patientForm.get('referringDoctors') as UntypedFormArray).clear();
      patient.referringDoctors.forEach(doctor => {
        (this.patientForm.get('referringDoctors') as UntypedFormArray).push(this.newDoctorFormGroup(doctor));
      });
    }
    if (patient.contactNumbers?.length > 0) {
      (this.patientForm.get('contactNumbers') as UntypedFormArray).clear();
      patient.contactNumbers.forEach(contact => {
        (this.patientForm.get('contactNumbers') as UntypedFormArray).push(this.newContactNumberFormGroup(contact));
      });
    }
    if (patient.emailAddresses?.length > 0) {
      (this.patientForm.get('emailAddresses') as UntypedFormArray).clear();
      patient.emailAddresses.forEach(email => {
        (this.patientForm.get('emailAddresses') as UntypedFormArray).push(this.newEmailAddressFormGroup(email));
      });
    }
    if (patient.addressDetails?.length > 0) {
      (this.patientForm.get('addressDetails') as UntypedFormArray).clear();
      patient.addressDetails.forEach(address => {
        (this.patientForm.get('addressDetails') as UntypedFormArray).push(this.newAddressFormGroup(address));
      });
    }
    if (patient.billingContact && patient.billingContact.billingContactContactNumbers?.length > 0) {
      (this.patientForm.get('billingContact').get('billingContactContactNumbers') as UntypedFormArray).clear();
      patient.billingContact.billingContactContactNumbers.forEach(contact => {
        (this.patientForm.get('billingContact').get('billingContactContactNumbers') as UntypedFormArray).push(this.newContactNumberFormGroup(contact));
      });
    }
    if (patient.billingContact && patient.billingContact.billingContactEmailAddresses?.length > 0) {
      (this.patientForm.get('billingContact').get('billingContactEmailAddresses') as UntypedFormArray).clear();
      patient.billingContact.billingContactEmailAddresses.forEach(email => {
        (this.patientForm.get('billingContact').get('billingContactEmailAddresses') as UntypedFormArray).push(this.newEmailAddressFormGroup(email));
      });
    }
    if (patient.billingContact && patient.billingContact.billingContactAddressDetails?.length > 0) {
      (this.patientForm.get('billingContact').get('billingContactAddressDetails') as UntypedFormArray).clear();
      patient.billingContact.billingContactAddressDetails.forEach(address => {
        (this.patientForm.get('billingContact').get('billingContactAddressDetails') as UntypedFormArray).push(this.newAddressFormGroup(address));
      });
    }
    if (patient.secondaryContacts?.length > 0) {
      (this.patientForm.get('secondaryContacts') as UntypedFormArray).clear();
      patient.secondaryContacts.forEach(secondaryContact => {
        const newContactFormGroup = this.newSecondaryContactFormGroup(secondaryContact);
        (this.patientForm.get('secondaryContacts') as UntypedFormArray).push(newContactFormGroup);
      });
    }
    this.patientForm.updateValueAndValidity();
  }
}
