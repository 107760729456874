import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ReportControl} from '../../report-builder/entities/report-control';
import {ReportControlEditorService} from '../report-control-editor.service';
import {Comment} from '../../comments-repository/entities/comment';
import {Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';

@Component({
  selector: 'app-comment-field-renderer',
  templateUrl: './comment-field-renderer.component.html',
  styleUrls: ['./comment-field-renderer.component.scss']
})
export class CommentFieldRendererComponent implements OnInit {
  @Input() control: ReportControl;
  value = '';

  constructor(private editorService: ReportControlEditorService) { }

  ngOnInit(): void {
    this.value = this.control.value;
  }

  onModelChanged(): void {
    this.editorService.triggerEvent();
  }
}
