<mat-card-content>
  <mat-card-title class="d-flex flex-row justify-content-between align-items-center mat-bold">
    {{plan.planDate | date:'yyyy-MM-dd'}}
    <button mat-icon-button  *appPermissionRequired="'Clinical.Documents.Write'" (click)="print(plan.id)" [disabled]="printing"><mat-icon>print</mat-icon></button>
  </mat-card-title>
  <mat-card-subtitle class="d-flex flex-row justify-content-between">
    <span>{{plan.lastModifiedDate | date:'yyyy-MM-dd HH:mm'}}</span>
    <span>{{plan.lastModifiedUser.firstName}} {{plan.lastModifiedUser.surname}}</span>
  </mat-card-subtitle>
</mat-card-content>
<mat-divider></mat-divider>
