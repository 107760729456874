<table mat-table [dataSource]="dataSource" class="w-100">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element">
      <div>
        <app-file-icon [extension]="element.extension" [grayscale]="element.processing"></app-file-icon>
        <span>{{element.fileName}}</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="uploaded">
    <th mat-header-cell *matHeaderCellDef class="d-responsive d-md"> Uploaded </th>
    <td mat-cell *matCellDef="let element" class="d-responsive d-md"> {{element.lastModifiedDate | date:'yyyy-MM-dd HH:mm':'+0200'}} </td>
  </ng-container>
  <ng-container matColumnDef="size">
    <th mat-header-cell *matHeaderCellDef class="d-responsive d-md"> Size </th>
    <td mat-cell *matCellDef="let element" class="d-responsive d-md"> {{element.size | fileSize}} </td>
  </ng-container>
  <ng-container matColumnDef="tags">
    <th mat-header-cell *matHeaderCellDef class="d-responsive d-lg"> Tags </th>
    <td mat-cell *matCellDef="let element" class="d-responsive d-lg">
      <mat-chip-listbox aria-label="tags">
        <mat-chip-option class="mat-tag" *ngFor="let tag of element.tags">{{tag.tag}}</mat-chip-option>
      </mat-chip-listbox>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions </th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button appConfirmationDialog [confirmMessage]="'Are you sure you want to edit this upload?'" (confirm)="appFileEditor.editAttachment()" [disabled]="appFileDownloader.processing | async" *appPermissionRequired="'Clinical.Uploads.Write'; condition: !element.processing"><mat-icon>edit</mat-icon></button>
      <button mat-icon-button appConfirmationDialog [confirmMessage]="'Are you sure you want to delete this upload?'" (confirm)="appFileEditor.deleteAttachment()" [disabled]="appFileDownloader.processing | async" *appPermissionRequired="'Clinical.Uploads.Delete'; condition: !element.processing"><mat-icon>delete</mat-icon></button>
      <button mat-icon-button (click)="appFileDownloader.openAttachment()" [disabled]="appFileDownloader.processing | async" *appPermissionRequired="'Clinical.Uploads.Read'; condition: !element.processing"><mat-icon>open_in_new</mat-icon></button>
      <app-file-downloader #appFileDownloader="appFileDownloader" [upload]="element"></app-file-downloader>
      <app-file-editor #appFileEditor="appFileEditor" [upload]="element"></app-file-editor>
      <mat-spinner *ngIf="element.processing" diameter="28"></mat-spinner>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.processing]="row.processing"></tr>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" [attr.colspan]="displayedColumns.length">
      There are no files that have been uploaded for this patient. You need to have  uploaded a file for this patient before it will show up here.
    </td>
  </tr>
</table>
