import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Patient} from 'src/app/modules/patient-repository/entities/patient';
import {TreatmentPlanItem} from 'src/app/modules/treatment-plans-repository/entities/treatment-plan-item';
import {TreatmentPlansRepositoryService} from 'src/app/modules/treatment-plans-repository/treatment-plans-repository.service';
import {AppMatAutocompleteComponent} from 'src/app/shared/components/app-mat-autocomplete/app-mat-autocomplete.component';
import {PatientService} from '../../patients/services/patient.service';
import {TreatmentPlanItemAddCardComponent} from '../treatment-plan-item-add-card/treatment-plan-item-add-card.component';
import {MatDialog} from '@angular/material/dialog';
import {UserSettings} from '../../user-repository/entities/user-settings';
import {CoreService} from '../../../core/services/core.service';
import {WebsocketService} from '../../../core/services/websocket.service';
import {PatientEventsService, PatientEvent} from '../../patients/services/patient-events.service';

@Component({
  selector: 'patient-treatment-plan-actions-widget',
  templateUrl: './treatment-plan-actions-widget.component.html',
  styleUrls: ['./treatment-plan-actions-widget.component.scss']
})
export class TreatmentPlanActionsWidgetComponent implements OnInit, OnDestroy {

  error = '';
  loading = false;
  settings: UserSettings;
  actions: TreatmentPlanItem[] = [];
  @ViewChild(AppMatAutocompleteComponent) autocomplete: AppMatAutocompleteComponent;
  private eventsSubscription: Subscription;
  private subscriptions: Subscription[] = [];
  private channelName = '';
  private readonly eventName = 'treatment_plan_action_item_changed';

  constructor(
    private matDialog: MatDialog,
    private patientEventService: PatientEventsService,
    private treatmentPlanItemsRepository: TreatmentPlansRepositoryService,
    private patientService: PatientService,
    private coreService: CoreService,
    private pushService: WebsocketService
  ) {
  }

  get hasError(): boolean {
    return this.error.length > 0;
  }

  get hasItems(): boolean {
    return this.actions && this.actions.length > 0;
  }

  get patientId(): number {
    return this.patientService.currentPatient.id;
  }

  get patient(): Patient {
    return this.patientService.currentPatient;
  }

  get confirmationMessage(): string {
    return 'Are you sure you want to save a treatment plan with these actions for ' + this.patient.firstName + ' ' + this.patient.surname + '?';
  }

  searchTreatmentPlanItems$ = (query: string): Observable<TreatmentPlanItem[]> => {
    return this.treatmentPlanItemsRepository.query(query)
      .pipe(map(response => response));
  }

  ngOnInit(): void {
    this.channelName = this.patientService.currentPatientEventsChannelName;
    this.settings = this.coreService.settings;
    this.subscriptions.push(this.patientService.patientTreatmentPlanItems$.subscribe(items => this.actions = [...items]));
    this.subscriptions.push(this.patientEventService.currentPatient$.subscribe(() => this.loadPatientTreatmentPlanItems()));
    this.eventsSubscription = this.patientEventService.events$.pipe(filter(e => e.type === 'treatment_plan_action_item_deleted')).subscribe(() => this.loadPatientTreatmentPlanItems());
    this.pushService.subscribe(this.channelName, this.eventName, _ => {
      this.loadPatientTreatmentPlanItems();
    });
  }

  ngOnDestroy(): void {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.pushService.unsubscribe(this.channelName, this.eventName);
  }

  onAddNewItem() {
    const modalRef = this.matDialog.open(TreatmentPlanItemAddCardComponent);
    this.subscriptions.push(modalRef.afterClosed().subscribe(result => {
      if (result) {
        this.autocomplete.search(result.action);
      }
    }));
  }

  onItemSelected(treatmentPlanItem: TreatmentPlanItem) {
    this.actions.push(treatmentPlanItem);
    this.addTreatmentPlanItemToPatient(treatmentPlanItem);
    this.autocomplete.cancelSearch();
  }

  savePlan() {
    this.treatmentPlanItemsRepository.savePlan(this.patientId, this.actions).subscribe(saved => {
      this.patientEventService.emitEvent(new PatientEvent('treatment_plans_updated'));
    }, error => {
      console.error(error);
    });
  }

  private loadPatientTreatmentPlanItems() {
    if (!this.patientService.hasPatientDetails) {
      return;
    }
    this.error = '';
    this.patientService.loadPatientTreatmentPlanItems();
  }

  private addTreatmentPlanItemToPatient(treatmentPlanItem: TreatmentPlanItem) {
    this.error = '';
    this.treatmentPlanItemsRepository.addTreatmentPlanItemToPatient(this.patientId, treatmentPlanItem).subscribe(() => {
      this.patientEventService.emitEvent(new PatientEvent('treatment_plan_action_item_added'));
    }, error => {
      this.error = error;
      this.loadPatientTreatmentPlanItems();
    });
  }

}
