import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {PatientService} from '../../../../patients/services/patient.service';
import {FilesRepositoryService} from '../../../../files-repository/files-repository.service';
import {Patient} from '../../../../patient-repository/entities/patient';
import {TreatmentPlan} from '../../../../treatment-plans-repository/entities/treatment-plan';
import {PatientEvent, PatientEventsService} from '../../../../patients/services/patient-events.service';

@Component({
  selector: 'app-treatment-plan-widget',
  templateUrl: './treatment-plan-widget.component.html',
  styleUrls: ['./treatment-plan-widget.component.scss']
})
export class TreatmentPlanWidgetComponent implements OnInit, OnDestroy {

  @Input() plan: TreatmentPlan;
  printing = false;
  private filesRepositorySubscription: Subscription;

  constructor(private patientService: PatientService, private patientEventService: PatientEventsService, private filesRepository: FilesRepositoryService) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if (this.filesRepositorySubscription) { this.filesRepositorySubscription.unsubscribe(); }
  }


  get patientId(): number {
    return this.patientService.currentPatient.id;
  }

  get patient(): Patient {
    return this.patientService.currentPatient;
  }

  get confirmationMessage(): string {
    return 'Are you sure you wish to print this treatment plan?';
  }

  print(id: number) {
    this.printing = true;
    this.filesRepositorySubscription = this.filesRepository.getUrl('plan', id, this.patientId).subscribe(
      url => {
        window.open(url, '_blank');
        this.printing = false;
        this.patientEventService.emitEvent(new PatientEvent('treatment_plans_updated'));
      }, error => {
        console.error(error);
        this.printing = false;
      });
  }

}
