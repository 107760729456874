<h2 mat-dialog-title>New Doctor</h2>
<mat-dialog-content>
  <form class="form" [formGroup]="form" *ngIf="form !== null" [class.form-validated]="validate">
    <div class="row">
      <div class="col-12 col-md-6">
        <mat-form-field appearance="fill" color="primary" class="w-100">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" type="text"/>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6">
        <mat-form-field appearance="fill" color="primary" class="w-100">
          <mat-label>Practice Number</mat-label>
          <input matInput formControlName="practiceNumber" type="text" appInputFormatter [formatPattern]="'practice_number'"/>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <mat-form-field appearance="fill" color="primary" class="w-100">
          <mat-label>Practice Phone Number</mat-label>
          <input matInput formControlName="tel" type="tel" appInputFormatter [formatPattern]="'phone_number'"/>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6">
        <mat-form-field appearance="fill" color="primary" class="w-100">
          <mat-label>Practice Email Address</mat-label>
          <input matInput formControlName="email" type="text"/>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <mat-form-field appearance="fill" color="primary" class="w-100">
          <mat-label>Practice Fax Number</mat-label>
          <input matInput formControlName="fax" type="tel" appInputFormatter [formatPattern]="'phone_number'"/>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <mat-form-field appearance="fill" color="primary" class="w-100">
          <mat-label>Preferred Greeting</mat-label>
          <input matInput formControlName="preferredGreeting" type="text" placeholder="eg Dr. Phil"/>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6">
        <mat-form-field appearance="fill" color="primary" class="w-100">
          <mat-label>Preferred Contact</mat-label>
          <mat-select formControlName="preferredContactMethod">
            <mat-option *ngFor="let option of contactMethodOptions" [value]="option.value">{{option.text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="close()">Cancel</button>
  <button mat-flat-button color="primary" (click)="save()">Add Doctor</button>
</mat-dialog-actions>
