import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PracticePatientProcedureEntry} from './entities/practice-patient-procedure-entry';
import {PracticePatientProcedureEntryCollectionResponse} from './entities/practice-patient-procedure-entry-collection-response';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {PracticePatientProcedureField} from './entities/practice-patient-procedure-field';
import {ApiService} from '../base/repository/api.service';

@Injectable({
  providedIn: 'root'
})
export class PatientProcedureRepositoryService {

  constructor(private api: ApiService) {
  }

  saveEntry(procedureId: number, patientId: number, entries: PracticePatientProcedureField[]): Observable<ItemCreatedResponse> {
    const payload = {
      procedureId: procedureId,
      patientId: patientId,
      entries: entries
    };
    return this.api.post<ItemCreatedResponse>('/procedures', payload)
      .pipe(map(response => response));
  }

  loadEntries(procedureId: number, patientId: number): Observable<PracticePatientProcedureEntry[]> {
    return this.api.get<PracticePatientProcedureEntryCollectionResponse>('/procedures/' + procedureId + '/patients/' + patientId)
      .pipe(map(response => response.items));
  }
}
