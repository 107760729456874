<h1>{{title}}</h1>

<div class="mat-button-group mb-3"  *appPermissionRequired="'Clinical.Comments.Write'; condition: !loading">
  <ng-container  *ngFor="let template of templates">
    <button mat-flat-button color="primary" type="button" (click)="selectTemplate(template.id)">{{template.name}}</button>
    <mat-divider vertical></mat-divider>
  </ng-container>
</div>

<app-loading-spinner *ngIf="loading"></app-loading-spinner>

<div class="mat-card-list comments-list" *appPermissionRequired="'Clinical.Comments.Read'">
  <mat-card class="compact" *ngFor="let comment of comments;" [style.backgroundColor]="comment.commentType.color">
    <mat-card-content>
      <mat-card-title-group>
        <mat-card-title class="mat-bold">{{comment.lastModifiedUser.firstName}} {{comment.lastModifiedUser.surname}}</mat-card-title>
        <mat-card-subtitle class="mb-2">
          {{comment.createdDate | date:'yyyy-MM-dd HH:mm'}}
        </mat-card-subtitle>
      </mat-card-title-group>
        <span class="multiline" *ngIf="comment.comment.text">
          {{comment.comment.text | textSanitize}}
        </span>
      </mat-card-content>
    <mat-divider inset></mat-divider>
      <mat-card-footer *ngIf="comment.uploads.length">
        <mat-list class="py-0 ms-4">
          @for (upload of comment.uploads; track upload.id){
            <mat-list-item class="text-start mat-list-item-compact clickable">
              <span>{{ upload.fileName }}</span>
              <div matListItemMeta class="mx-0">
                <button mat-icon-button [disabled]="appFileDownloader.processing | async" *appPermissionRequired="'Clinical.Uploads.Read'" (click)="appFileDownloader.openAttachment()"><mat-icon>attach_file</mat-icon></button>
              </div>
              <app-file-downloader #appFileDownloader="appFileDownloader" [upload]="upload"></app-file-downloader>
            </mat-list-item>
          }
        </mat-list>
      </mat-card-footer>
      <mat-divider></mat-divider>
  </mat-card>
  <mat-card *ngIf="!hasComments">
    <mat-card-content>
      <span>No comments have been added to this patient.</span>
    </mat-card-content>
  </mat-card>
</div>
