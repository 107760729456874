import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DraftCollectionResponse} from './entities/draft-collection-response';
import {Draft} from './entities/draft';
import {ItemDeletedResponse} from '../base/entities/item-deleted-response';
import {ApiService} from '../base/repository/api.service';

@Injectable({
  providedIn: 'root'
})
export class PatientDraftsRepositoryService {

  constructor(private api: ApiService) {}

  delete(id: number, draft: Draft): Observable<boolean> {
    return this.api.delete<ItemDeletedResponse>('/patients/' + id + '/drafts/' + draft.draftType + '/' + draft.draftId)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  loadAll(id: number): Observable<Draft[]> {
    return this.api.get<DraftCollectionResponse>('/patients/' + id + '/drafts')
      .pipe(map(response => response.items));
  }
}
