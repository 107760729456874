import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {DraftsService, PatientDraft} from 'src/app/core/services/drafts.service';
import {CommentsRepositoryService} from '../../comments-repository/comments-repository.service';
import {Subscription} from 'rxjs';
import {Comment} from '../../comments-repository/entities/comment';
import {PatientService} from '../../patients/services/patient.service';
import {JumpTextRendererComponent} from '../../report-renderer/jump-text-renderer/jump-text-renderer.component';
import {Patient} from '../../patient-repository/entities/patient';
import {PatientEvent, PatientEventsService} from '../../patients/services/patient-events.service';
import {DraftInteractionBehaviour} from '../../../core/layout/draft-overlay/draft-interaction-behaviour';

@Component({
  selector: 'app-create-comment',
  templateUrl: './create-comment.component.html',
  styleUrls: ['./create-comment.component.scss']
})
export class CreateCommentComponent implements OnInit, OnDestroy, DraftInteractionBehaviour {

  @ViewChild('jumpTextRendererComponent') jumpTextRendererComponent: JumpTextRendererComponent;
  @Input() draft: PatientDraft;
  currentComment: Comment = null;
  loading = false;
  errorMessage = '';
  tags: string[] = ['patient', 'comment'];
  private commentsSubscription: Subscription[] = [];
  private debounceTimeout: NodeJS.Timeout;

  constructor(
    private draftService: DraftsService,
    private commentsRepository: CommentsRepositoryService,
    private patientService: PatientService,
    private patientEventService: PatientEventsService
  ) {
  }

  get hasErrorMessage(): boolean {
    return this.errorMessage.length > 0;
  }

  get saveButtonEnabled(): boolean {
    return !this.loading;
  }

  get patient(): Patient {
    return this.patientService.currentPatient;
  }

  get hasPatientDetails(): boolean {
    return this.patientService.hasPatientDetails;
  }

  ngOnInit(): void {
    if (this.draft.draftId !== 0) {
      this.loadCommentDetails();
    }
  }

  ngOnDestroy(): void {
    this.commentsSubscription.forEach(subscription => subscription.unsubscribe());
  }

  onTextReceived(text: string) {
    this.currentComment.comment.text += text;
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
        this.updateComment(false);
      },
      1500);
  }

  save(): void {
    this.updateComment(true);
  }

  onModelChange(text: string) {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
        this.updateComment(false);
      },
      1500);
  }

  onUploadComplete(result: boolean): void {
    if (result) {
      this.loadCommentDetails();
    }
  }

  private loadCommentDetails() {
    this.loading = true;
    this.commentsSubscription.push(this.commentsRepository.get(this.draft.draftId).subscribe(comment => {
      this.currentComment = comment;
      this.loading = false;
    }, error => {
      this.errorMessage = 'Could not load comment';
      this.loading = false;
      console.error(error);
    }));
  }

  private updateComment(locked: boolean): void {
    this.currentComment.locked = locked;
    this.loading = locked;
    this.commentsSubscription.push(this.commentsRepository.update(this.currentComment).subscribe(() => {
      this.loading = false;
      if (locked) {
        this.draftService.closeDraft(this.draft.index);
        this.patientEventService.emitEvent(new PatientEvent('comments_updated'));
      }
    }, error => {
      this.errorMessage = 'Could not save changes to this comment';
      this.loading = false;
      console.error(error);
    }));
  }
}
