import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportControlEditorService {

  private eventsSubject: Subject<EditorEvent> = new Subject();
  events$: Observable<EditorEvent> = this.eventsSubject.asObservable();

  constructor() {
  }

  triggerEvent() {
    const event = new EditorEvent('value_changed');
    this.eventsSubject.next(event);
  }
}

export type EditorEventType =
  |'value_changed';

export class EditorEvent {
  constructor(readonly type: EditorEventType) {
  }
}
