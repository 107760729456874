<h2 mat-dialog-title>New Medication Item</h2>
<mat-dialog-content>
  <form class="form" [formGroup]="form" *ngIf="form !== null" [class.form-validated]="validate">
    <div class="row">
      <div class="col">
        <mat-form-field appearance="fill" color="primary" class="w-100">
          <mat-label>Name</mat-label>
          <input matInput formControlName="description" type="text"/>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-5">
        <app-mat-autocomplete *ngIf="!hasType" class="w-100" placeholder="Search Medication Types" [searchObservable]="searchMedicationTypes$" (addNewItem)='addNewType()'
                              [displayResultsCardTemplate]='displayResultsCardTemplate' [addItemCardText]="'Add new medication type.'" ></app-mat-autocomplete>
        <ng-template #displayResultsCardTemplate let-result="result" let-queryText="queryText">
          <div class="d-flex flex-row justify-content-between align-items-center cursor-pointer" (click)="typeSelected(result)">
            <span [innerHtml]="result.description | textHighlight: queryText"></span>
          </div>
        </ng-template>
        <mat-form-field appearance="fill" color="primary" class="w-100" *ngIf="hasType">
          <mat-label>Medication Type</mat-label>
          <input matInput type="text" readonly value="{{medicationType?.description}}"/>
          <button matSuffix mat-icon-button (click)="clearType()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="fill" color="primary" class="w-100">
          <mat-label>Dosage</mat-label>
          <input matInput formControlName="dosage" type="text"/>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-4">
        <mat-form-field appearance="fill" color="primary" class="w-100">
          <mat-label>Diagnosis Code</mat-label>
          <input matInput formControlName="diagnosisCode" type="text"/>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="mx-3">
  <button type="button" mat-button (click)="close()">Close</button>
  <button type="button" mat-flat-button color="primary" (click)="save()">Save changes</button>
</mat-dialog-actions>
