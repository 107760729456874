import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {SharedModule} from '../../../shared/shared.module';
import {Observable, Subscription} from 'rxjs';
import {UpdateUpload, Upload, UploadTag} from '../../upload-repository/entities/upload';
import {BaseTag} from '../../base/entities/base-tag';
import {map} from 'rxjs/operators';
import {FileUploaderService, UploadType} from '../file-uploader.service';
import {FileUploadEventsService} from '../file-upload-events.service';
import {FileSizeFormatterPipe} from '../../../shared/pipes/file-size-formatter.pipe';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {query} from '@angular/animations';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';

export interface FileUpdaterComponentBottomSheetData {
  upload: Upload;
}
@Component({
  selector: 'app-file-updater',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    SharedModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  templateUrl: './file-updater.component.html',
  styleUrl: './file-updater.component.scss'
})
export class FileUpdaterComponent implements OnInit, OnDestroy {
  private uploadEventsSubscription: Subscription;
  processing = false;
  upload: Upload;
  fileTags: BaseTag[] = [{ tag: 'patient', hexColor: '#ffffff' } as BaseTag];
  tags: string[] = [];
  type: UploadType = 'practice';

  constructor(private uploaderService: FileUploaderService,
              private uploadEvents: FileUploadEventsService,
              private fileSizePipe: FileSizeFormatterPipe,
              @Inject(MAT_BOTTOM_SHEET_DATA) private data: FileUpdaterComponentBottomSheetData,
              private bottomSheetRef: MatBottomSheetRef<FileUpdaterComponent>
  ) {
    if (this.data && this.data.upload) {
      this.upload = this.data.upload;
    }
    if (this.upload) {
      this.fileTags = this.upload.tags.map(tag => { return { tag: tag.tag, hexColor: tag.hexColor } as BaseTag; });
    }
  }

  ngOnInit(): void {
    console.log(this.upload);
  }

  ngOnDestroy(): void {
  }



  searchTags = (query: string): Observable<BaseTag[]> => {
    return this.uploaderService.queryTags(query)
      .pipe(map(response => response));
  }

  onTagsChange(tags: BaseTag[]) {
    this.fileTags = tags;
  }

  cancel() {
    this.bottomSheetRef.dismiss();
  }

  update() {
    this.processing = true;
    this.uploaderService.updateUpload(this.upload.id, this.generateUpload()).then((result) => {
      if (result) {
        this.bottomSheetRef.dismiss();
      }
    }).finally(() => {
      this.processing = false;
    });
  }

  private generateUpload(): UpdateUpload {
    return {
      id: this.upload.id,
      tags: this.fileTags,
      fileName: this.upload.fileName,
    };
  }
}
