import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {DraftsService, PatientDraft} from '../../../core/services/drafts.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {PatientService} from '../../patients/services/patient.service';
import {CoreService} from '../../../core/services/core.service';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {Script} from '../../scripts-repository/entities/script';
import {ScriptRepositoryService} from '../../scripts-repository/script-repository.service';
import {Medication} from '../../medication-repository/entities/medication';
import {MedicationRepositoryService} from '../../medication-repository/medication-repository.service';
import {AppMatAutocompleteComponent} from '../../../shared/components/app-mat-autocomplete/app-mat-autocomplete.component';
import {MedicationItemAddCardComponent} from '../medication-item-add-card/medication-item-add-card.component';
import {MatDialog} from '@angular/material/dialog';
import {PatientEvent, PatientEventsService} from '../../patients/services/patient-events.service';
import {DraftInteractionBehaviour} from '../../../core/layout/draft-overlay/draft-interaction-behaviour';

@Component({
  selector: 'app-create-script',
  templateUrl: './create-script.component.html',
  styleUrls: ['./create-script.component.scss']
})
export class CreateScriptComponent implements OnInit, OnDestroy, DraftInteractionBehaviour {

  @Input() draft: PatientDraft;
  form: UntypedFormGroup = null;
  script: Script = null;
  loading = false;
  saving = false;
  @ViewChild(AppMatAutocompleteComponent) autocomplete: AppMatAutocompleteComponent;
  private subscriptions: Subscription[] = [];
  private triggerTextChanges = false;
  private debounceTimeOut = 1500;

  constructor(
    private dialog: MatDialog,
    private draftService: DraftsService,
    private formBuilder: UntypedFormBuilder,
    private patientService: PatientService,
    private patientEventService: PatientEventsService,
    private coreService: CoreService,
    private scriptsRepository: ScriptRepositoryService,
    private medicationRepository: MedicationRepositoryService
  ) {
  }

  get saveButtonEnabled(): boolean {
    return this.formValid && !this.saving;
  }

  get formValid(): boolean {
    return this.form && this.form.valid;
  }

  get isEditing(): boolean {
    return this.draft.draftId !== null && this.script !== null;
  }

  get hasMedication(): boolean {
    return this.medication.length > 0;
  }

  get medication(): Medication[] {
    return this.script.medicationList;
  }

  searchMedication$ = (query: string): Observable<Medication[]> => {
    return this.medicationRepository.query(query)
      .pipe(map(response => response));
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      patientName: ['', Validators.required],
      scriptDate: [new Date(), Validators.required],
      medicalAidName: [''],
      medicalAidNumber: [''],
    });
    this.getScriptDetails();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onAddNewItem() {
    const DialogRef = this.dialog.open(MedicationItemAddCardComponent, { panelClass: 'mat-dialog-responsive'});
    this.subscriptions.push(DialogRef.afterClosed().subscribe(result => {
      console.log(result);
    }));
  }

  medicationItemChanged() {
    this.formChanged();
  }

  onItemSelected(medication: Medication) {
    this.script.medicationList.push(medication);
    this.autocomplete.cancelSearch();
    this.formChanged();
  }

  save() {
    this.formChanged(true);
  }

  getScriptDetails() {
    this.loading = true;
    this.subscriptions.push(this.scriptsRepository.load(this.draft.draftId).subscribe(script => {
      this.script = script;
      this.dataLoaded();
    }, error => {
      console.error(error);
    }));
  }


  confirmationMessage(item: Medication): string {
    return 'Are you sure you want to remove ' + item.description + ' this script?';
  }

  public removeMedicationFromScript(index: number) {
    this.script.medicationList.splice(index, 1);
    this.formChanged();
  }

  private dataLoaded(): void {
    this.handleExistingScriptDataLoaded();
    this.subscribeToFormChanges();
    this.loading = false;
  }

  private handleExistingScriptDataLoaded(): void {
    this.form.get('patientName').setValue(this.script.patientName);
    this.form.get('scriptDate').setValue(this.script.scriptDate);
    this.form.get('medicalAidName').setValue(this.script.medicalAidName);
    this.form.get('medicalAidNumber').setValue(this.script.medicalAidNumber);
    this.form.updateValueAndValidity();
    this.triggerTextChanges = true;
  }

  private subscribeToFormChanges(): void {
    this.subscriptions.push(this.form.valueChanges.pipe(debounceTime(this.debounceTimeOut), distinctUntilChanged()).subscribe(() => {
      this.formChanged();
    }));
  }

  private formChanged(locked: boolean = false) {
    if (this.saving) {
      return;
    }
    if (this.form.valid) {
      this.updateScript(locked);
    }
  }

  private updateScript(locked: boolean): void {
    const updateScript = this.form.value as Script;
    const scriptDate = this.form.get('scriptDate').value;
    updateScript.scriptDate = new Date(scriptDate);
    updateScript.medicationList = this.script.medicationList;
    updateScript.locked = locked;
    updateScript.duration = 0;
    updateScript.scriptType = this.script.scriptType;
    this.subscriptions.push(this.scriptsRepository.updateScript(this.script.id, updateScript).subscribe(() => {
      if (updateScript.locked) {
        this.draftService.closeDraft(this.draft.index);
        this.patientEventService.emitEvent(new PatientEvent('scripts_updated'));
      }
      this.saving = false;
    }, error => {
      this.coreService.showErrorToast('Could not save changes to this script');
      console.error(error);
      this.saving = false;
    }));
  }
}
