import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {Medication} from '../medication-repository/entities/medication';
import {Script} from './entities/script';
import {ScriptCollectionResponse} from './entities/script-collection-response';
import {ScriptType} from './entities/script-type';
import {ScriptItemResponse} from './entities/script-item-response';
import {ApiService} from '../base/repository/api.service';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';

@Injectable({
  providedIn: 'root'
})
export class ScriptRepositoryService {

  constructor(private api: ApiService) {
  }

  saveScript(patientId: number, medicationList: Medication[], scriptType: ScriptType): Observable<number> {
    const script = {
      type: scriptType,
      medication: medicationList
    };
    const scriptTypeUri = scriptType === ScriptType.ACUTE ? 'acute' : 'chronic';
    return this.api.post<ItemCreatedResponse>('/scripts/patient/' + patientId + '/' + scriptTypeUri, script)
      .pipe(map(response => response.value));
  }

  updateScript(scriptId: number, script: Script): Observable<boolean> {
    return this.api.post<ItemUpdatedResponse>('/scripts/' + scriptId, script)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  loadPatientScripts(patientId: number): Observable<Script[]> {
    return this.api.get<ScriptCollectionResponse>('/scripts/patient/' + patientId)
      .pipe(map(response => response.items));
  }

  load(scriptId: number): Observable<Script> {
    return this.api.get<ScriptItemResponse>('/scripts/' + scriptId)
      .pipe(map(response => response.value));
  }

}
