import {Injectable} from '@angular/core';
import {NewUpload, Upload} from '../upload-repository/entities/upload';
import {UploadCollectionResponse} from '../upload-repository/entities/upload-collection-response';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BaseTag} from '../base/entities/base-tag';
import {TagCollectionResponse} from '../upload-repository/entities/tag-collection-response';
import {ApiService} from '../base/repository/api.service';
import {ItemCreatedResponse} from '../base/entities/item-created-response';

@Injectable({
  providedIn: 'root'
})
export class PatientUploadsRepositoryService {

  constructor(private api: ApiService) {
  }

  loadAll(id: number, tags: string[]): Observable<Upload[]> {
    const tagsParam = tags.length > 0 ? {tags: tags.join(',')} : null;
    return this.api.get<UploadCollectionResponse>('/uploads/patients/' + id, tagsParam)
      .pipe(map(response => response.items));
  }

  queryTags(query: string): Observable<BaseTag[]> {
    return this.api.get<TagCollectionResponse>('/uploads/tags/search/', {query: query})
      .pipe(map(response => response.items));
  }

  create(upload: NewUpload): Observable<number> {
    return this.api.post<ItemCreatedResponse>('/uploads/patients/' + upload.patientId, upload)
      .pipe(map(response => response.value));
  }
}
