import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {JumpTextRendererComponent} from './jump-text-renderer/jump-text-renderer.component';
import {SharedModule} from '../../shared/shared.module';
import {NgCdkModule} from '../../ng-cdk.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ReportControlEditorComponent} from './report-control-editor/report-control-editor.component';
import {DatePickerRendererComponent} from './date-picker-renderer/date-picker-renderer.component';
import {MultiLineTextFieldRendererComponent} from './multi-line-text-field-renderer/multi-line-text-field-renderer.component';
import {SingleLineTextFieldRendererComponent} from './single-line-text-field-renderer/single-line-text-field-renderer.component';
import {CommentFieldRendererComponent} from './comment-field-renderer/comment-field-renderer.component';
import {MedicationListRendererComponent} from './medication-list-renderer/medication-list-renderer.component';
import {ProblemListRendererComponent} from './problem-list-renderer/problem-list-renderer.component';
import {TreatmentPlanListRendererComponent} from './treatment-plan-list-renderer/treatment-plan-list-renderer.component';
import {PatientSummaryRendererComponent} from './patient-summary-renderer/patient-summary-renderer.component';
import {ReportJumpTextRendererComponent} from './report-jump-text-renderer/report-jump-text-renderer.component';
import {SectionTitleRendererComponent} from './section-title-renderer/section-title-renderer.component';
import {ReportTitleRendererComponent} from './report-title-renderer/report-title-renderer.component';
import {ProcedureFieldRendererComponent} from './procedure-field-renderer/procedure-field-renderer.component';
import {TranscribeModule} from '../transcribe/transcribe.module';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatLineModule, MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';


@NgModule({
  declarations: [
    JumpTextRendererComponent,
    ReportControlEditorComponent,
    DatePickerRendererComponent,
    MultiLineTextFieldRendererComponent,
    SingleLineTextFieldRendererComponent,
    CommentFieldRendererComponent,
    MedicationListRendererComponent,
    ProblemListRendererComponent,
    TreatmentPlanListRendererComponent,
    PatientSummaryRendererComponent,
    ReportJumpTextRendererComponent,
    SectionTitleRendererComponent,
    ReportTitleRendererComponent,
    ProcedureFieldRendererComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        NgCdkModule,
        FormsModule,
        ReactiveFormsModule,
        TranscribeModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatLineModule,
        MatOptionModule,
        MatSelectModule,
    ],
  exports: [
    JumpTextRendererComponent,
    ReportControlEditorComponent,
    ReportTitleRendererComponent
  ]
})
export class ReportRendererModule {
}
