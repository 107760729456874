<div class="row" [formGroup]="formGroup">
  <div class="col-12 col-md-6 d-flex flex-row justify-content-between align-items-start">
    <input type="hidden" formControlName="type">
    <input type="hidden" formControlName="label">
    <mat-form-field class="w-25 me-3" appearance="fill" color="primary">
      <mat-label>Label</mat-label>
      <input type="text"
             placeholder="Pick one"
             matInput
             [formControl]="internalFormControl"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of displayTypes" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="flex-spacer" appearance="fill" color="primary">
      <mat-label>Number</mat-label>
      <input matInput formControlName="number" type="tel" maxlength="12" appInputFormatter [formatPattern]="'phone_number'" />
      <mat-error>Contact number is required.</mat-error>
    </mat-form-field>
    <button mat-icon-button *ngIf="showDelete" (click)="deleteItem()"><mat-icon>delete</mat-icon></button>
  </div>
</div>
