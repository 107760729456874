import {Injectable} from '@angular/core';
import {Message} from './entities/sms';
import {MessageCollectionResponse} from './entities/sms-collection-response';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {MessageItemResponse} from './entities/sms-item-response';
import {ApiService} from '../base/repository/api.service';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';

@Injectable({
  providedIn: 'root'
})
export class PatientMessagesRepositoryService {

  constructor(private api: ApiService) {
  }

  loadAll(id: number): Observable<Message[]> {
    return this.api.get<MessageCollectionResponse>('/messages/patient/' + id)
      .pipe(map(response => response.items));
  }

  load(id: number): Observable<Message> {
    return this.api.get<MessageItemResponse>('/messages/' + id)
      .pipe(map(response => response.value));
  }

  create(message: Message): Observable<number> {
    return this.api.post<ItemCreatedResponse>('/messages/', message)
      .pipe(map(response => response.value));
  }

  update(message: Message): Observable<boolean> {
    return this.api.put<ItemUpdatedResponse>('/messages/' + message.id, message)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  send(message: Message): Observable<boolean> {
    return this.api.put<ItemUpdatedResponse>('/messages/' + message.id + '/send', message)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }
}
