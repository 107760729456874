import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CommentsRepositoryService} from 'src/app/modules/comments-repository/comments-repository.service';
import {Comment} from 'src/app/modules/comments-repository/entities/comment';
import {Patient} from 'src/app/modules/patient-repository/entities/patient';
import {PatientService} from '../../patients/services/patient.service';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {CoreService} from '../../../core/services/core.service';
import {PracticeCommentTemplate} from '../../comments-repository/entities/practice-comment-template';
import {CommentText} from '../../comments-repository/entities/comment-text';
import {DraftsService} from '../../../core/services/drafts.service';
import {WebsocketService} from '../../../core/services/websocket.service';
import {CoreEventsService} from '../../../core/services/core-events.service';
import {PatientEventsService} from '../../patients/services/patient-events.service';


@Component({
  selector: 'patient-comments-widget',
  templateUrl: './comments-widget.component.html',
  styleUrls: ['./comments-widget.component.scss']
})
export class CommentsWidgetComponent implements OnInit, OnDestroy {

  constructor(private patientService: PatientService, private patientEventService: PatientEventsService, private coreEventService: CoreEventsService, private coreService: CoreService, private commentsRepository: CommentsRepositoryService, private draftService: DraftsService, private pushService: WebsocketService) {
  }

  get hasError(): boolean {
    return this.error.length > 0;
  }

  get hasComments(): boolean {
    return this.comments.length > 0;
  }

  get patientId(): number {
    return this.patientService.currentPatient.id;
  }

  get patient(): Patient {
    return this.patientService.currentPatient;
  }

  private subscriptions: Subscription[] = [];
  private currentComment: Comment = null;
  private channelName = '';
  private readonly eventName = 'comments_changed';
  error = '';
  loading = true;
  comments: Comment[] = [];
  commentTemplates: PracticeCommentTemplate[] = [];
  templates: PracticeCommentTemplate[] = [];

  @Input() title = 'Comments';
  @Input() types: number[] = [1];

  private static replaceChars(string: string, find: string, replace: string): string {
    const regExp = RegExp(CommentsWidgetComponent.escapeRegExp(find), 'g');
    return string.replace(regExp, replace).trim();
  }

  private static escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  ngOnInit(): void {
    this.channelName = this.patientService.currentPatientEventsChannelName;
    this.commentTemplates = this.coreService.commentTemplates;
    this.subscriptions.push(this.patientEventService.currentPatient$.subscribe(() => {
      this.loadPatientComments();
    }));
    this.subscriptions.push(this.patientEventService.events$.pipe(filter(event => event.type === 'comments_updated')).subscribe(() => {
      this.loadPatientComments();
    }));
    this.subscriptions.push(this.coreEventService.events$.pipe(filter(event => event.type === 'comment_templates_loaded')).subscribe(event => {
      if (event.type === 'comment_templates_loaded') {
        this.commentTemplates = this.coreService.commentTemplates;
        this.setTemplates();
      }
    }));
    this.setTemplates();
    this.pushService.subscribe(this.channelName, this.eventName, data => {
      if (data && data.type && this.types.indexOf(data.type) > -1) {
        this.loadPatientComments();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.pushService.unsubscribe(this.channelName, this.eventName);
  }

  selectTemplate(templateId: number): void {
    const commentTemplate = this.commentTemplates.find(c => c.id === templateId);
    this.createComment(commentTemplate);
  }

  private setTemplates() {
    if (this.commentTemplates && this.commentTemplates.length > 0) {
      this.templates = this.commentTemplates.filter(t => this.types.includes(t.type.id)).sort((a, b) => a.type.priority > b.type.priority ? 1 : -1);
    }
  }

  private loadPatientComments() {
    if (!this.patientService.hasPatientDetails) {
      return;
    }
    this.error = '';
    this.subscriptions.push(this.commentsRepository.loadPatientComments(this.patientId, this.types).subscribe(comments => {
      this.comments = [...comments];
      this.loading = false;
    }, error => {
      this.error = 'Could not load comments for this patient';
      this.loading = false;
    }));
  }

  createComment(commentTemplate: PracticeCommentTemplate) {
    this.currentComment = this.createNewComment(commentTemplate);
    this.commentsRepository.create(this.currentComment).subscribe(commentId => {
      this.currentComment = null;
      this.draftService.addNewDraft(this.patient.id, this.patient.profileId, this.patient.practiceId, 'comment', commentId, 'New Comment');
    }, error => {
      this.coreService.showErrorToast('Could not create new comment');
    });
  }

  private createNewComment(commentTemplate: PracticeCommentTemplate): Comment {
    return {
      id: null,
      typeId: commentTemplate.type.id,
      templateId: commentTemplate.id,
      commentType: commentTemplate.type,
      commentTemplate: commentTemplate,
      comment: {
        text: commentTemplate.commentTemplate.text,
        template: commentTemplate.commentTemplate
      } as CommentText,
      locked: false,
      patientId: this.patient.id,
      practiceId: this.coreService.selectedPractice.id,
      createdDate: new Date(),
      lastModifiedDate: new Date(),
      createdUser: this.coreService.currentUser,
      lastModifiedUser: this.coreService.currentUser,
      deleted: false,
    } as Comment;
  }
}
