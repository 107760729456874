import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {TreatmentPlanItem} from './entities/treatment-plan-item';
import {TreatmentPlanItemsCollectionResponse} from './entities/treatment-plan-items-collection-response';
import {TreatmentPlan} from './entities/treatment-plan';
import {TreatmentPlansCollectionResponse} from './entities/treatment-plans-collection-response';
import {TreatmentPlanPaginationResponse} from './entities/treatment-plan-pagination-response';
import {ApiService} from '../base/repository/api.service';
import {AdminApiService} from '../base/repository/admin-api.service';
import {ItemDeletedResponse} from '../base/entities/item-deleted-response';
import {GenericPaginationResponse} from '../base/entities/generic-pagination-response';

@Injectable({
  providedIn: 'root'
})
export class TreatmentPlansRepositoryService {

  constructor(private api: ApiService, private adminApi: AdminApiService) {

  }

  query(query: string): Observable<TreatmentPlanItem[]> {
    return this.api.get<TreatmentPlanItemsCollectionResponse>('treatmentplans/search/', {'query': query})
      .pipe(map(response => response.items));
  }

  loadPatientTreatmentPlanItems(patientId: number): Observable<TreatmentPlanItem[]> {
    return this.api.get<TreatmentPlanItemsCollectionResponse>('treatmentplans/patient/' + patientId)
      .pipe(map(response => response.items));
  }

  addTreatmentPlanItemToPatient(patientId: number, planItem: TreatmentPlanItem): Observable<number> {
    return this.api.post<ItemCreatedResponse>('treatmentplans/patient/' + patientId, planItem)
      .pipe(map(response => response.value));
  }

  removeTreatmentPlanItemFromPatient(patientId: number, planitemId: number): Observable<boolean> {
    return this.api.delete<ItemDeletedResponse>('treatmentplans/patient/' + patientId + '/' + planitemId)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  loadPatientTreatmentPlans(patientId: number): Observable<TreatmentPlan[]> {
    return this.api.get<TreatmentPlansCollectionResponse>('treatmentplans/patient/' + patientId + '/plans')
      .pipe(map(response => response.items));
  }

  savePlan(patientId: number, actions: TreatmentPlanItem[]): Observable<number> {
    return this.api.post<ItemCreatedResponse>('treatmentplans/patient/' + patientId + '/plans', {actions: actions})
      .pipe(map(response => response.value));
  }

  createTreatmentPlanItem(treatmentPlanItem: TreatmentPlanItem): Observable<number> {
    return this.api.post<ItemCreatedResponse>('treatmentplans', treatmentPlanItem)
      .pipe(map(response => response.value));
  }

  adminPaginate(type: string, pageNumber: number, pageSize: number, filter: string): Observable<TreatmentPlanPaginationResponse> {
    return this.adminApi.get<GenericPaginationResponse<TreatmentPlanPaginationResponse>>('treatmentplans/' + type, {'pageNumber': pageNumber, 'pageSize': pageSize, 'filter': filter})
      .pipe(map(response => response.value));
  }

  adminCreateTreatmentPlanItem(treatmentPlanItem: TreatmentPlanItem): Observable<number> {
    return this.adminApi.post<ItemCreatedResponse>('treatmentplans', treatmentPlanItem)
      .pipe(map(response => response.value));
  }

  adminUpdateTreatmentPlanItem(id: number, treatmentPlanItem: TreatmentPlanItem): Observable<number> {
    return this.adminApi.put<ItemCreatedResponse>('treatmentplans/' + id, treatmentPlanItem)
      .pipe(map(response => response.value));
  }

  adminDeletePlan(id: number): Observable<boolean> {
    return this.adminApi.delete<ItemDeletedResponse>('treatmentplans/' + id)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }
}
