import {Component, Input, OnInit} from '@angular/core';
import {ReportControl} from '../../report-builder/entities/report-control';
import {ReportControlEditorService} from '../report-control-editor.service';

@Component({
  selector: 'app-procedure-field-renderer',
  templateUrl: './procedure-field-renderer.component.html',
  styleUrls: ['./procedure-field-renderer.component.scss']
})
export class ProcedureFieldRendererComponent implements OnInit {

  @Input() control: ReportControl;

  constructor(private editorService: ReportControlEditorService) { }

  ngOnInit(): void {
  }

  onValueChanges() {
    this.editorService.triggerEvent();
  }

}
