<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div *ngIf="showSelection && !loading && hasTemplates">
  <h1>Select Report</h1>
  <span class="mat-card-subtitle">Please select the report you would like to create</span>
  <div class="d-flex flex-column justify-content-start align-items-center">
    <button mat-flat-button color="primary" class="w-100 mb-3" type="button" *ngFor="let template of templates" (click)="selectTemplate(template.id)">{{template.template.title}}</button>
  </div>
</div>
<div *ngIf="!showSelection && !loading" class="report">
  <div *ngFor="let row of report.template.rows; let rowIndex = index;" class="report-row-container mb-3">
    <div class="report-row row" *ngIf="row.isRow">
      <div *ngFor="let control of row.controls; let colIndex = index;" class="report-control col col-sm {{control.widthClass}}">
        <app-report-control-editor [patientId]="report.patientId" [control]="control" (deleteControl)="onDelete(rowIndex, colIndex)"></app-report-control-editor>
      </div>
    </div>
    <div class="report-row report-row-title w-100" *ngIf="row.isRowTitle">
      <app-report-title-renderer [row]="row" [template]="report.template" [entryOptions]="entryOptions" (procedureEntryChange)="selectProcedureEntry($event)">
      </app-report-title-renderer>
    </div>
  </div>
</div>
