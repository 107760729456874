<h1>Medication Listing</h1>

<app-mat-autocomplete placeholder="Search Medication" [alwaysShowAddCard]="true" [searchObservable]="searchMedication$" (addNewItem)='onAddNewItem()' [addItemCardText]="'Add new medication item'"
    [displayResultsCardTemplate]='displayResultsCardTemplate' *appPermissionRequired="'Clinical.Medication.Write'"></app-mat-autocomplete>
<ng-template #displayResultsCardTemplate let-result="result" let-queryText="queryText">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <span [innerHtml]="result.description + ' ' + result.dosage + ' ' + result.diagnosisCode | textHighlight: queryText"></span>
      <button mat-icon-button (click)="onItemSelected(result)"><mat-icon>add</mat-icon></button>
    </div>
    <mat-form-field color="primary" appearance="fill" class="compact w-100" subscriptSizing="dynamic">
      <mat-label>Comments/Notes</mat-label>
      <input matInput type="text" [(ngModel)]="result.comment"/>
    </mat-form-field>
</ng-template>

<app-loading-spinner *ngIf="loading"></app-loading-spinner>

<div class="mat-card-list" *appPermissionRequired="'Clinical.Medication.Read';">
  <ng-container  *ngFor="let item of medication;">
    <mat-card class="compact" [class.duplicates]="hasDuplicates(item)" [class.duplicate-types]="hasDuplicateTypes(item)">
      <app-medication-item [item]="item" [usePrimaryText]="settings.themeWidgetText" class="w-100"></app-medication-item>
    </mat-card>
  </ng-container>
  <mat-card *ngIf="!hasMedication">
    <mat-card-content>
      <span>No medication items have been added to this patient.</span>
    </mat-card-content>
  </mat-card>
</div>
<button mat-flat-button color="primary" class="w-100" *appPermissionRequired="'Clinical.Scripts.Write'; condition: hasMedication" appConfirmationDialog [confirmMessage]="confirmationMessage" (confirm)="saveScript()">Save Script</button>

