import { Injectable } from '@angular/core';
import { UploadRepositoryService } from 'src/app/modules/upload-repository/upload-repository.service';
import { PatientUploadsRepositoryService } from 'src/app/modules/patient-uploads-repository/patient-uploads-repository.service';
import { AuthenticationService } from 'src/app/core/auth/authentication.service';
import {Upload, NewUpload, UpdateUpload} from 'src/app/modules/upload-repository/entities/upload';
import { VerifyStorageAvailableResponse } from '../upload-repository/entities/verify-storage-available-response';
import { TotalStorageUsageResponse } from '../upload-repository/entities/total-storage-usage-response';
import {BehaviorSubject, Observable} from 'rxjs';
import {BaseTag} from '../base/entities/base-tag';

@Injectable({
    providedIn: 'root'
})
export class FileUploaderService {

    private uploadsBehaviourSubject: BehaviorSubject<Upload[]> = new BehaviorSubject([]);
    uploads: Upload[] = [];
    uploads$ = this.uploadsBehaviourSubject.asObservable();

    constructor(private uploadRepository: UploadRepositoryService, private patientUploadsRepository: PatientUploadsRepositoryService, private authService: AuthenticationService) { }

    uuidv4(): string {
        return AuthenticationService.uuidv4();
    }

    getSignedUrl(file: string, action: string, expires: number): Promise<string> {
        return new Promise((resolve, reject) => {
            this.uploadRepository.getSignedUrl(file, action, expires).subscribe(result => resolve(result), error => reject(error));
        });
    }

    uploadFile(file: File, url: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.uploadRepository.uploadFile(file, url).subscribe(result => resolve(result), error => reject(error));
        });
    }

    saveUpload(upload: NewUpload, type: UploadType): Promise<number> {
        if (type === 'patient') {
            return new Promise((resolve, reject) => {
                this.patientUploadsRepository.create(upload).subscribe(result => {
                    this.uploads.unshift(this.convertUpload(upload, result));
                    this.uploadsBehaviourSubject.next(this.uploads);
                    resolve(result);
                }, error => reject(error));
            });
        }
        if (type === 'practice') {
            return new Promise((resolve, reject) => {
                this.patientUploadsRepository.create(upload).subscribe(result => {
                    this.uploads.unshift(this.convertUpload(upload, result));
                    this.uploadsBehaviourSubject.next(this.uploads);
                    resolve(result);
                }, error => reject(error));
            });
        }
    }

    getTotalStorageUsage(): Promise<TotalStorageUsageResponse> {
        return new Promise((resolve, reject) => {
            this.uploadRepository.getTotalStorageUsage().subscribe(result => resolve(result), error => reject(error));
        });
    }

    verifyStorageAvailable(size: number): Promise<VerifyStorageAvailableResponse> {
        return new Promise((resolve, reject) => {
            this.uploadRepository.verifyStorageAvailable(size).subscribe(result => resolve(result), error => reject(error));
        });
    }

    queryTags(query: string): Observable<BaseTag[]> {
      return this.patientUploadsRepository.queryTags(query);
    }

    loadUploads(patientId: number, type: UploadType, tags: string[]): Promise<Upload[]> {
      return new Promise((resolve, reject) => {
        this.patientUploadsRepository.loadAll(patientId, tags).subscribe(result => {
          this.uploads = result;
          this.uploadsBehaviourSubject.next(this.uploads);
          resolve(result);
        }, error => {
          this.uploads = [];
          reject(error);
        });
      });
    }

    updateUpload(uploadId: number, upload: UpdateUpload): Promise<boolean> {
      return new Promise((resolve, reject) => {
        this.uploadRepository.updateFile(uploadId, upload).subscribe(result => {
          if (result) {
            const index = this.uploads.findIndex(u => u.id === uploadId);
            if (index >= 0) {
              this.uploads[index] = {
                ...this.uploads[index],
                ...upload
              };
              this.uploadsBehaviourSubject.next(this.uploads);
            }
          }
          resolve(result);
        }, error => reject(error));
      });
    }

    deleteUpload(uploadId: number): Promise<boolean> {
      return new Promise((resolve, reject) => {
        this.uploadRepository.deleteFile(uploadId).subscribe(result => {
          if (result) {
            this.uploads = this.uploads.filter(upload => upload.id !== uploadId);
            this.uploadsBehaviourSubject.next(this.uploads);
          }
          resolve(result);
        }, error => reject(error));
      });
    }

    convertUpload(upload: NewUpload, id: number): Upload {
        return {
            id: id,
            practiceId: null,
            patientId: null,
            commentId: null,
            tags: upload.tags,
            fileName: upload.fileName,
            extension: upload.extension,
            path: upload.path,
            size: upload.size,
            createdDate: new Date(),
            lastModifiedDate: new Date(),
            createdUser: null,
            lastModifiedUser: null,
            deleted: false,
            processing: false
        };
    }
}

export type UploadType =
    | 'patient'
    | 'practice';
