import {Injectable} from '@angular/core';
import {MedicalSchemeCollectionResponse} from './entities/medical-scheme-collection-response';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {MedicalScheme} from './entities/medical-scheme';
import {ApiService} from '../base/repository/api.service';

@Injectable({
  providedIn: 'root'
})
export class MedicalSchemesRepositoryService {

  constructor(private api: ApiService) {
  }


  query(query: string): Observable<MedicalScheme[]> {
    return this.api.get<MedicalSchemeCollectionResponse>('/masterdata/medical-aid-schemes/search/', {query: query})
      .pipe(map(response => response.items));
  }
}
