<div class="uploader">
  <div class="mb-3" *ngIf="verifying">
    <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
  </div>
  <div class="error" *ngIf="!storageAvailable">
        <span>
          Unfortunately you dont have sufficient storage available in your account to perform this upload.<br>
          You have used {{totalStorageUsedSize | fileSize}} out of {{totalStorageSize | fileSize}} and only have {{availableStorageSize | fileSize}} available.<br>
          You require {{totalSize | fileSize}} to perform this upload. Please remove one or more files from your selection to ensure your uploads fit into your available storage.
        </span>
  </div>
  <div class="d-flex flex-column">
    <app-file-upload [tags]="tags" [file]="file" [index]="i" (deleteFile)="deleteFile(i)" [type]="type" [patientId]="patientId" [commentId]="commentId" *ngFor="let file of files;let i = index"></app-file-upload>
  </div>
  <div class="total-upload-progress d-flex flex-row flex-nowrap align-items-center">
    <div class="flex-grow-1 d-flex flex-column me-3">
      <mat-progress-bar mode="determinate" color="primary" [value]="fileUploadProgress" ></mat-progress-bar>
      <div class="d-flex justify-content-between align-items-center">
        <span>{{fileUploadCount}} of {{totalFiles}} uploaded</span>
        <span>{{fileSizeProgress | fileSize}}/{{totalSize | fileSize}}</span>
      </div>
    </div>
    <button mat-button color="primary" (click)="clearFiles()" [disabled]="processing">Clear Files</button>
    <button mat-flat-button color="primary" (click)="uploadFiles()" [disabled]="processing || !storageAvailable">Upload Files</button>
  </div>
</div>
