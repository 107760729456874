import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import { TotalStorageUsageResponse } from '../../upload-repository/entities/total-storage-usage-response';
import { PatientService } from '../../patients/services/patient.service';
import { FileUploaderService } from '../file-uploader.service';
import { Subscription } from 'rxjs';
import {ThemePalette} from '@angular/material/core';

@Component({
  selector: 'app-upload-storage-usage',
  templateUrl: './upload-storage-usage.component.html',
  styleUrls: ['./upload-storage-usage.component.scss']
})
export class UploadStorageUsageComponent implements OnInit, OnDestroy {

    @Input() storageUsage: TotalStorageUsageResponse = null;

    constructor(private uploadService: FileUploaderService) { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    get hasStorageUsage(): boolean {
        return this.storageUsage != null;
    }

    get storageBarColor(): ThemePalette {
      if (this.storageUsage?.storageUsedPercentage > 90.0) {
        return 'warn';
      } else {
        return 'primary';
      }
    }
}
