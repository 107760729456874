<h1>Appointment Listing</h1>
<app-mat-date-time-picker [label]="'Appointment Time'"  [name]="'appointmentDate'" [processing]="saving" [parentForm]="form" (action)="saveAppointment()" class="col-sm-12" *appPermissionRequired="'Clinical.Appointments.Write'"></app-mat-date-time-picker>
<app-loading-spinner *ngIf="loading"></app-loading-spinner>
<div class="mat-card-list" *appPermissionRequired="'Clinical.Appointments.Read';">
  <ng-container  *ngFor="let appointment of appointments;">
    <mat-card class="compact">
      <mat-card-content class="pb-0 pt-2">
        <app-appointment-widget [appointment]="appointment" [types]="appointmentTypes" class="w-100"></app-appointment-widget>
      </mat-card-content>
    </mat-card>
  </ng-container>
  <mat-card class="compact" *ngIf="!hasAppointments">
    <mat-card-content >No appointments have been created for this patient.</mat-card-content>
  </mat-card>
</div>
