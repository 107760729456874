import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {FileUploaderComponent} from '../file-uploader/file-uploader.component';
import {Subscription} from 'rxjs';
import {UploadType} from '../file-uploader.service';

@Component({
  selector: 'app-file-upload-button',
  templateUrl: './file-upload-button.component.html',
  styleUrls: ['./file-upload-button.component.scss']
})
export class FileUploadButtonComponent implements OnInit, OnDestroy {
  @Input() patientId: number;
  @Input() commentId: number;
  @Input() tags: string[] = [];
  @Input() type: UploadType = 'practice';
  @Input() iconButton = false;
  @Output() complete = new EventEmitter<boolean>();
  @ViewChild('fileInput') fileInput: ElementRef;
  private bottomSheetSubscription: Subscription;
  private bottomSheetRef: MatBottomSheetRef;
  constructor(private bottomSheet: MatBottomSheet) { }

  ngOnInit(): void {
    this.bottomSheetRef = null;
  }

  ngOnDestroy(): void {
    this.bottomSheetSubscription?.unsubscribe();
    this.bottomSheetRef = null;
  }

  filesLoaded(event: any) {
    const files = event.target.files;
    this.bottomSheetRef = this.bottomSheet.open(FileUploaderComponent, {
      disableClose: true,
      data: {
        files: files,
        tags: this.tags,
        type: this.type,
        patientId: this.patientId,
        commentId: this.commentId
      }
    });
    this.bottomSheetSubscription = this.bottomSheetRef.afterDismissed().subscribe( dismissed => {
      this.fileInput.nativeElement.value = null;
      this.complete.next(dismissed);
    });
  }

}
