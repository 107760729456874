import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Patient} from 'src/app/modules/patient-repository/entities/patient';
import {Problem} from 'src/app/modules/problems-repository/entities/problem';
import {ProblemListRepositoryService} from 'src/app/modules/problems-repository/problem-list-repository.service';
import {AppMatAutocompleteComponent} from 'src/app/shared/components/app-mat-autocomplete/app-mat-autocomplete.component';
import {PatientService} from '../../patients/services/patient.service';
import {ProblemItemAddCardComponent} from '../problem-item-add-card/problem-item-add-card.component';
import {MatDialog} from '@angular/material/dialog';
import {UserSettings} from '../../user-repository/entities/user-settings';
import {CoreService} from '../../../core/services/core.service';
import {WebsocketService} from '../../../core/services/websocket.service';
import {PatientEvent, PatientEventsService} from '../../patients/services/patient-events.service';

@Component({
  selector: 'patient-problem-widget',
  templateUrl: './problem-widget.component.html',
  styleUrls: ['./problem-widget.component.scss']
})
export class ProblemWidgetComponent implements OnInit, OnDestroy {

  error = '';
  loading = false;
  settings: UserSettings;
  problems: Problem[] = [];
  @ViewChild(AppMatAutocompleteComponent) autocomplete: AppMatAutocompleteComponent;
  private subscriptions: Subscription[] = [];
  private channelName = '';
  private readonly eventName = 'problem_list_changed';

  constructor(
    private matDialog: MatDialog,
    private problemsRepository: ProblemListRepositoryService,
    private patientService: PatientService,
    private coreService: CoreService,
    private pushService: WebsocketService,
    private patientEventService: PatientEventsService
  ) {
  }

  get hasError(): boolean {
    return this.error.length > 0;
  }

  get hasProblems(): boolean {
    return this.problems.length > 0;
  }

  get patientId(): number {
    return this.patientService.currentPatient.id;
  }

  get patient(): Patient {
    return this.patientService.currentPatient;
  }

  searchProblems$ = (query: string): Observable<Problem[]> => {
    return this.problemsRepository.query(query)
      .pipe(map(response => response));
  }

  ngOnInit(): void {
    this.channelName = this.patientService.currentPatientEventsChannelName;
    this.settings = this.coreService.settings;
    this.subscriptions.push(this.patientEventService.currentPatient$.subscribe(() => this.loadPatientProblemList()));
    this.subscriptions.push(this.patientService.patientProblems$.subscribe(problems => this.problems = [...problems]));
    this.subscriptions.push(this.patientEventService.events$.pipe(filter(e => e.type === 'problem_item_deleted')).subscribe(() => this.loadPatientProblemList()));
    this.pushService.subscribe(this.channelName, this.eventName, data => {
      console.log('ProblemWidgetComponent: pushMessageReceived', data);
      this.loadPatientProblemList();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.pushService.unsubscribe(this.channelName, this.eventName);
  }

  onAddNewItem() {
    const dialogRef = this.matDialog.open(ProblemItemAddCardComponent, {
      panelClass: 'mat-dialog-responsive'
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.autocomplete.search(result.problem);
      }
    }));
  }

  onItemSelected(problem: Problem) {
    this.problems.push(problem);
    this.addProblemToPatient(problem);
    this.autocomplete.cancelSearch();
  }

  private loadPatientProblemList() {
    if (!this.patientService.hasPatientDetails) {
      return;
    }
    this.patientService.loadPatientProblems();
  }

  private addProblemToPatient(problem: Problem) {
    this.error = '';
    this.subscriptions.push(this.problemsRepository.addProblemToPatient(this.patientId, problem).subscribe(() => {
      this.patientEventService.emitEvent(new PatientEvent('problem_item_added'));
    }, error => {
      this.error = 'Could not add new problem item to this patient';
      this.loadPatientProblemList();
    }));
  }

}
