export class UploadedFile {
    name: string;
    size: number;
    progress: number;
    error: string;

    constructor(name: string, size: number){
        this.name = name;
        this.size = size;
        this.progress = 0;
        this.error = '';
    }

    get sizeProgress(): number {
        return this.size * (this.progress / 100);
    }

    get complete(): boolean {
        return this.progress === 100;
    }
}
