<mat-card appearance="outlined" class="mb-3">
  <mat-card-header>
    <div class="d-flex justify-content-between align-items-center w-100">
      <mat-icon class="avatar">{{folder.icon}}</mat-icon>
      <mat-card-title>{{folder.name}}</mat-card-title>
      <div class="flex-spacer"></div>
      <app-file-upload-button *appPermissionRequired="'Clinical.Uploads.Write'; condition: hasPatientDetails" [patientId]="currentPatientId" [tags]="tags" [type]="'patient'">Upload</app-file-upload-button>
    </div>
  </mat-card-header>
  <mat-card-content>

    <mat-list>
      @for (upload of filteredUploads; track upload.id){
      <mat-list-item lines="3" >
        <app-file-icon class="mt-3 me-3" matListItemAvatar [extension]="upload.extension" [grayscale]="upload.processing" (click)="appFileDownloader.openAttachment()"></app-file-icon>
        <div matListItemTitle (click)="appFileDownloader.openAttachment()">{{ upload.fileName }}</div>
        <div matListItemLine (click)="appFileDownloader.openAttachment()">{{ upload.size | fileSize }}</div>
        <div matListItemLine (click)="appFileDownloader.openAttachment()">{{ upload.lastModifiedDate | date:'yyyy-MM-dd HH:mm':'+0200' }}</div>
        <div matListItemMeta>
          <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{upload: upload}" (click)="$event.preventDefault()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item appConfirmationDialog [confirmMessage]="'Are you sure you want to edit this upload?'" (confirm)="appFileEditor.editAttachment()" [disabled]="appFileDownloader.processing | async" *appPermissionRequired="'Clinical.Uploads.Write'; condition: !upload.processing"><mat-icon>edit</mat-icon>Edit</button>
            <button mat-menu-item appConfirmationDialog [confirmMessage]="'Are you sure you want to delete this upload?'" (confirm)="appFileEditor.deleteAttachment()" [disabled]="appFileDownloader.processing | async" *appPermissionRequired="'Clinical.Uploads.Delete'; condition: !upload.processing"><mat-icon>delete</mat-icon>Delete</button>
            <app-file-downloader #appFileDownloader="appFileDownloader" [upload]="upload"></app-file-downloader>
            <app-file-editor #appFileEditor="appFileEditor" [upload]="upload"></app-file-editor>
          </mat-menu>
        </div>
      </mat-list-item>
      }
      @if (filteredUploads.length === 0){
        <mat-list-item>
          <span matListItemTitle> No files have been uploaded in this folder.</span>
        </mat-list-item>
      }
    </mat-list>

  </mat-card-content>
</mat-card>

