import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {Medication} from './entities/medication';
import {MedicationCollectionResponse} from './entities/medication-collection-response';
import {MedicationPaginationResponse} from './entities/medication-pagination-response';
import {MedicationTypePaginationResponse} from './entities/medication-type-pagination-response';
import {MedicationType} from './entities/medication-type';
import {MedicationTypeCollectionResponse} from './entities/medication-type-collection-response';
import {ApiService} from '../base/repository/api.service';
import {AdminApiService} from '../base/repository/admin-api.service';
import {GenericPaginationResponse} from '../base/entities/generic-pagination-response';
import {ItemDeletedResponse} from '../base/entities/item-deleted-response';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';

@Injectable({
  providedIn: 'root'
})
export class MedicationRepositoryService {

  constructor(private api: ApiService, private adminApi: AdminApiService) {
  }

  query(query: string): Observable<Medication[]> {
    return this.api.get<MedicationCollectionResponse>('/medication/search/', {query: query})
      .pipe(map(response => response.items));
  }

  queryTypes(query: string): Observable<MedicationType[]> {
    return this.api.get<MedicationTypeCollectionResponse>('/medication/types/search/', {query: query})
      .pipe(map(response => response.items));
  }

  loadPatientMedication(patientId: number): Observable<Medication[]> {
    return this.api.get<MedicationCollectionResponse>('/medication/patient/' + patientId)
      .pipe(map(response => response.items));
  }

  addMedicationToPatient(patientId: number, medication: Medication) {
    return this.api.post<ItemCreatedResponse>('/medication/patient/' + patientId, medication)
      .pipe(map(response => response));
  }

  removeMedicationFromPatient(patientId: number, medicationId: number) {
    return this.api.delete<ItemDeletedResponse>('/medication/patient/' + patientId + '/' + medicationId)
      .pipe(map(response => response));
  }

  createMedicationItem(medication: Medication): Observable<number> {
    return this.api.post<ItemCreatedResponse>('/medication', medication)
      .pipe(map(response => response.value));
  }

  adminPaginate(type: string, pageNumber: number, pageSize: number, filter: string): Observable<MedicationPaginationResponse> {
    return this.adminApi.get<GenericPaginationResponse<MedicationPaginationResponse>>('/medication/' + type, {pageNumber: pageNumber, pageSize: pageSize, filter: filter})
      .pipe(map(response => response.value));
  }

  adminCreateMedication(medication: Medication): Observable<number> {
    return this.adminApi.post<ItemCreatedResponse>('/medication', medication)
      .pipe(map(response => response.value));
  }

  adminUpdateMedication(id: number, medication: Medication): Observable<boolean> {
    return this.adminApi.put<ItemUpdatedResponse>('/medication/' + id, medication)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  adminDeleteMedication(id: number): Observable<boolean> {
    return this.adminApi.delete<ItemDeletedResponse>('/medication/' + id)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  adminPaginateTypes(type: string, pageNumber: number, pageSize: number, filter: string): Observable<MedicationTypePaginationResponse> {
    return this.adminApi.get<GenericPaginationResponse<MedicationTypePaginationResponse>>('/medication/types/' + type, {pageNumber: pageNumber, pageSize: pageSize, filter: filter})
      .pipe(map(response => response.value));
  }

  adminCreateMedicationType(medicationType: MedicationType): Observable<number> {
    return this.adminApi.post<ItemCreatedResponse>('/medication/types/', medicationType)
      .pipe(map(response => response.value));
  }

  adminUpdateMedicationType(id: number, medicationType: MedicationType): Observable<boolean> {
    return this.adminApi.put<ItemUpdatedResponse>('/medication/types/' + id, medicationType)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  adminDeleteMedicationType(id: number): Observable<boolean> {
    return this.adminApi.delete<ItemDeletedResponse>('/medication/types/' + id)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  adminQueryTypes(query: string): Observable<MedicationType[]> {
    return this.adminApi.get<MedicationTypeCollectionResponse>('/medication/types/search/', {query: query})
      .pipe(map(response => response.items));
  }

}
