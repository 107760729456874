import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export class CustomValidators {

  constructor() {
  }

  static printAllFormErrors(formGroup: UntypedFormGroup | UntypedFormArray, path: string = ''): any[] {
    const errors = [];
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key] as UntypedFormControl | UntypedFormGroup | UntypedFormArray;
      const name = this.getControlName(control);
      const keypath = path === '' ? name : `${path}.${name}`;
      if (control instanceof UntypedFormControl) {
        if (control.errors !== null) {
          errors.push({ key: keypath, control: control });
        }
      } else if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {        // {5}
        errors.push(...this.printAllFormErrors(control, keypath))  ;      // {6}
      } else {
        console.error('invalid type', control, key, typeof control);
      }
    });
    return errors;
  }

  static validateAllFormFields(formGroup: UntypedFormGroup | UntypedFormArray) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key] as UntypedFormControl | UntypedFormGroup | UntypedFormArray;
      if (control instanceof UntypedFormControl) {   // {4}
        control.markAsDirty();          // {4}
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {        // {5}
        this.validateAllFormFields(control);            // {6}
      } else {
        console.error('invalid type', control, key, typeof control);
      }
    });
  }

  static atLeastOneValueRequired(...args: { formArrayName: string, formControlName: string }[]): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const controls: UntypedFormControl[] = [];
      let validControls = false;
      args.forEach(field => {
        const controlArray = group.get(field.formArrayName) as UntypedFormArray;
        if (controlArray) {
          controlArray.controls.forEach((innerGroup: UntypedFormGroup) => {
            const control = innerGroup.controls[field.formControlName] as UntypedFormControl;
            if (control) {
              if (control.value && control.value.length > 0) { validControls = true; }
              controls.push(control);
            }
          });
        }
      });
      if (validControls) {
        controls.forEach(control => {
          if (control.errors && control.errors['atLeastOnValueRequired']){
            delete control.errors['atLeastOnValueRequired'];
            if (Object.keys(control.errors).length === 0) {
              control.setErrors(null);
            }
            control.updateValueAndValidity();
          }
        });
        return null;
      }
      controls.forEach(control => control.setErrors({ atLeastOnValueRequired: true}));
      return null;
    };
  }

  static conditionallyRequired(): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      if (control && (control.dirty || control.touched) && control.value.length === 0) {
        return { required: true };
      }
      return;
    };
  }

  static duplicateFieldMatchRequired(fieldName: string, matchFieldName: string): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const control = group.get(fieldName) as UntypedFormControl;
      const matchedControl = group.get(matchFieldName) as UntypedFormControl;
      if (control === null || matchedControl === null) {
        return null;
      }
      if (!control.value) {
        return null;
      }
      if (control.value.length === 0) {
        return null;
      }
      if (control.value !== matchedControl.value) {
        matchedControl.setErrors({ notMatching: true });
        return null;
      }
      return null;
    };
  }

  static practiceNumberValidator(): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      const value = (control && control.value) ? control.value.replace(/\D/g, '') : '';
      if (value.length !== 13) {
        return {invalid: true};
      }
      return;
    };
  }

  static identityNumberValidator(performLuhnCheck: boolean = false): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      const value = (control && control.value) ? control.value.replace(/\D/g, '') : '';
      // valid if it is empty
      if (value.length === 0) {
        return;
      }
      // if it is not 13 digits long there is a problem
      if (value.length !== 13) {
        return {invalid: true};
      }
      // check parameter and if needed, do checksum validation with the Luhn algorithm
      if (performLuhnCheck && !this.luhnCheck(value)) {
        return {invalid: true};
      }
      return;
    };
  }

  private static luhnCheck(num: number | string): boolean {
    let value = num.toString();
    if (/[^0-9-\s]+/.test(value)) {
      return false;
    }
    // The Luhn Algorithm. It's so pretty.
    let nCheck = 0;
    let bEven = false;
    value = value.replace(/\D/g, '');
    for (let n = value.length - 1; n >= 0; n--) {
      const cDigit = value.charAt(n);
      let nDigit = parseInt(cDigit, 10);
      if (bEven && (nDigit *= 2) > 9) {
        nDigit -= 9;
      }
      nCheck += nDigit;
      bEven = !bEven;
    }
    return (nCheck % 10) === 0;
  }

  private static getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }
}
