import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {QuickNote} from './entities/quick-note-entity';
import {QuickNoteResponse} from './entities/quick-note-response';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';
import {ApiService} from '../base/repository/api.service';

@Injectable({
  providedIn: 'root'
})
export class PatientQuickNotesRepositoryService {

  constructor(private api: ApiService) {
  }

  load(patientId: number): Observable<QuickNote> {
    return this.api.get<QuickNoteResponse>('/patients/' + patientId + '/quicknote')
      .pipe(map(response => response.value));
  }

  update(patientId: number, note: QuickNote): Observable<boolean> {
    return this.api.put<ItemUpdatedResponse>('/patients/' + patientId + '/quicknote', note)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

}
