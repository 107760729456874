<div class="app-report-control report-{{control.title.replace(' ', '-')}} d-flex flex-row-reverse align-items-start" [class.section-title]="control.isSectionTitle">
  <button mat-icon-button (click)="delete()"><mat-icon class="md-20">delete</mat-icon></button>
    <div class="content">
        <div class="report-control" [class.inline]="control.inline">
            <app-procedure-field-renderer *ngIf="control.isProcedureField" [control]="control"></app-procedure-field-renderer>
            <app-single-line-text-field-renderer *ngIf="control.isSingleLineTextField" [control]="control"></app-single-line-text-field-renderer>
            <app-date-picker-renderer *ngIf="control.isDatePicker" [control]="control"></app-date-picker-renderer>
            <app-multi-line-text-field-renderer *ngIf="control.isMultiLineTextField" [control]="control"></app-multi-line-text-field-renderer>
            <app-multi-line-text-field-renderer *ngIf="control.isJumpTextField" [control]="control"></app-multi-line-text-field-renderer>
            <app-comment-field-renderer *ngIf="control.isLatestComment" [control]="control"></app-comment-field-renderer>
            <app-problem-list-renderer *ngIf="control.isProblemList" [patientId]="patientId" [control]="control"></app-problem-list-renderer>
            <app-medication-list-renderer *ngIf="control.isMedicationList" [patientId]="patientId" [control]="control"></app-medication-list-renderer>
            <app-treatment-plan-list-renderer *ngIf="control.isTreatmentPlanList" [patientId]="patientId" [control]="control"></app-treatment-plan-list-renderer>
            <app-patient-summary-renderer *ngIf="control.isPatientSummary"  [control]="control"></app-patient-summary-renderer>
        </div>
        <app-section-title-renderer *ngIf="control.isSectionTitle" [control]="control"></app-section-title-renderer>
    </div>
</div>
