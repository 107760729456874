<div class="d-flex align-items-center mb-2">
  <span class="flex-grow-1 mat-bold">{{appointment.start | date:'yyyy-MM-dd HH:mm'}}</span>
  <button mat-flat-button class="px-1" [matMenuTriggerFor]="menu" *appPermissionRequired="'Clinical.Appointments.Write'; else readonlyType" [backgroundColor]="appointment.appointmentType.displayStyle" [opacity]="1" appBackgroundColourOpacity>
    {{appointment.appointmentType.title}}
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let type of types" [style.background]="type.displayStyle" (click)="selectType(type)">{{type.title}}</button>
  </mat-menu>
  <button mat-icon-button (click)="toggleIsOpen()"><mat-icon *ngIf="!isOpen">arrow_drop_down</mat-icon><mat-icon *ngIf="isOpen">arrow_drop_up</mat-icon></button>
</div>
<div class="secondary-content" [class.open]="isOpen">
  <mat-form-field class="compact w-100" appearance="fill" color="primary" *appPermissionRequired="'Clinical.Appointments.Write'; else readonlyComment" >
    <mat-label>Comment</mat-label>
    <textarea matInput [(ngModel)]="appointment.comment" (ngModelChange)="onCommentChange($event)" [rows]="3"></textarea>
  </mat-form-field>
</div>
<mat-card-subtitle class="mb-2">
  <div class="d-flex justify-content-between">
    <span class="">{{appointment.lastModifiedDate | date:'yyyy-MM-dd HH:mm'}}</span>
    <span class="">{{appointment.lastModifiedUser.firstName}} {{appointment.lastModifiedUser.surname}}</span>
  </div>
</mat-card-subtitle>
<mat-divider class="mx-0"></mat-divider>
<ng-template #readonlyComment>
  <mat-form-field class="w-100" color="primary" [floatLabel]="'always'" class="compact">
    <mat-label>Comment</mat-label>
    <textarea matInput [(ngModel)]="appointment.comment"  readonly></textarea>
  </mat-form-field>
</ng-template>
<ng-template #readonlyType>
  <button mat-button [backgroundColor]="appointment.appointmentType.displayStyle" [opacity]="1" appBackgroundColourOpacity>{{appointment.appointmentType.title}}</button>
</ng-template>
