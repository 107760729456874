<div class="quicknote" *ngIf="hasNote">
  <app-loading-bar *ngIf="loading"></app-loading-bar>
  <textarea class="form-control" [(ngModel)]="note.note"  (ngModelChange)='noteTextChanged.next($event)' rows="10" *appPermissionRequired="'Clinical.Patients.Write'; else: readOnlyQuicknote"></textarea>
  <div class="row mx-2 my-1">
    <div class="col-sm-12 col-md-6 p-0">
      <p class="subtext text-muted">{{note.lastModifiedDate | date:'yyyy-MM-dd HH:mm'}}</p>
    </div>
    <div class="col-sm-12 col-md-6 p-0">
      <p class="subtext text-muted text-start text-md-end">{{note.lastModifiedUser.firstName}} {{note.lastModifiedUser.surname}}</p>
    </div>
  </div>
</div>
<ng-template #readOnlyQuicknote>
  <textarea class="form-control" [(ngModel)]="note.note" rows="10" readonly></textarea>
</ng-template>
