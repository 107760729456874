import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApiService} from '../base/repository/api.service';
import {FileUrlItemResponse} from './entities/file-url-item-response';

@Injectable({
  providedIn: 'root'
})
export class FilesRepositoryService {

  constructor(private api: ApiService) {
  }

  getUrl(fileType: string, fileId: number, patientId: number): Observable<string> {
    return this.api.get<FileUrlItemResponse>('documents/generate-url', {fileType: fileType, fileId: fileId, patientId: patientId})
      .pipe(map(response => this.api.baseUrl + '/' + response.value));
  }
}
