<div  class="files-list d-flex flex-row align-items-center justify-content-between">
    <div class="d-flex flex-column flex-grow-1">
      <div class="d-flex flex-row justify-content-between">
        <span>
          {{file.name}}
        </span>
        <span>
          {{uploadProgressSize | fileSize}}/{{file.size | fileSize}}
        </span>
      </div>
      <mat-progress-bar mode="determinate" color="primary" [value]="uploadProgressPercentage" ></mat-progress-bar>
    </div>
    <app-tag-search class="w-40 ms-3" [searchObservable]="searchTags" [(tags)]="fileTags" (tagsChange)="onTagsChange($event)"></app-tag-search>
    <button mat-icon-button (click)="deleteAttachment()" [class.disabled]="uploading || processing || hasError || complete"><mat-icon>delete</mat-icon></button>
</div>
