<mat-card-content>
  <mat-card-title class="d-flex align-items-start mat-bold mb-2" [class.primary]="usePrimaryText" matTooltip="{{item.type.description}}" [matTooltipDisabled]="item.type.unassigned || true">
    {{item.description}} {{item.dosage}} {{item.diagnosisCode}}
    <span class="flex-spacer"></span>
    <button class="mat-card-button-top-right-corner" mat-icon-button *appPermissionRequired="'Clinical.Medication.Delete'" appConfirmationDialog [confirmMessage]="confirmationMessage" (confirm)="removeMedicationFromPatient(item.id)"><mat-icon>delete</mat-icon></button>
  </mat-card-title>
  <span *ngIf="item.comment && item.comment.length > 0">{{item.comment | textSanitize}}</span>
  <mat-card-subtitle class="d-flex justify-content-between">
    <span class="">{{item.lastModifiedDate | date:'yyyy-MM-dd HH:mm'}}</span>
    <span class="">{{item.lastModifiedUser.firstName}} {{item.lastModifiedUser.surname}}</span>
  </mat-card-subtitle>
</mat-card-content>
<mat-divider></mat-divider>
