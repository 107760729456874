import {Component, input, Input, OnChanges, OnInit} from '@angular/core';
import {Upload} from '../../../upload-repository/entities/upload';
import {UploadMenuItem} from '../../../practice-settings-repository/entities/upload-menu-item';
import {PatientService} from '../../../patients/services/patient.service';
import {UploadType} from '../../file-uploader.service';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrl: './folder.component.scss'
})
export class FolderComponent implements OnInit, OnChanges {
  @Input() uploads: Upload[] = [];
  @Input() folder: UploadMenuItem;
  @Input() type: UploadType = 'practice';
  filteredUploads: Upload[] = [];
  tags: string[] = [];

  constructor(private patientService: PatientService) {
  }

  get currentPatientId(): number {
    return this.patientService.currentPatient.id;
  }

  get hasPatientDetails(): boolean {
    return this.patientService.hasPatientDetails;
  }

  ngOnInit() {
    this.filterUploads();
  }

  ngOnChanges() {
    this.filterUploads();
  }

  private filterUploads() {
    this.tags = [this.type, this.folder.path];
    this.filteredUploads = this.uploads.filter(upload => {
      return this.tags.every(tag => upload.tags.map(t => t.tag).includes(tag));
    }).sort((a, b) => a.createdDate > b.createdDate ? -1 : 1);
  }
}
