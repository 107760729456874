import {Injectable} from '@angular/core';
import {EmailItemResponse} from './entities/email-item-response';
import {Email} from './entities/email';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EmailCollectionResponse} from './entities/email-collection-response';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {ApiService} from '../base/repository/api.service';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';

@Injectable({
  providedIn: 'root'
})
export class PatientEmailsRepositoryService {

  constructor(private api: ApiService) {
  }

  loadAll(id: number): Observable<Email[]> {
    return this.api.get<EmailCollectionResponse>('/emails/patient/' + id)
      .pipe(map(response => response.items));
  }

  load(id: number): Observable<Email> {
    return this.api.get<EmailItemResponse>('/emails/' + id)
      .pipe(map(response => response.value));
  }

  create(email: Email): Observable<number> {
    return this.api.post<ItemCreatedResponse>('/emails/', email)
      .pipe(map(response => response.value));
  }

  update(email: Email): Observable<boolean> {
    return this.api.put<ItemUpdatedResponse>('/emails/' + email.id, email)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  send(email: Email): Observable<boolean> {
    return this.api.put<ItemUpdatedResponse>('/emails/' + email.id + '/send', email)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

}
