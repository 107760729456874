<div class="patient-summary">
  <div class="row">
    <div *ngIf="control.displayPracticeDetails" class="col-sm"><h2 class="mb-0">{{treatingDoctorName}}</h2></div>
  </div>
  <div class="row">
    <div class="col-sm"><h1 class="mb-0"><span>{{patient.profileId | patientNumberPad}}</span> - {{patient.displayName}}</h1></div>
  </div>
  <div class="row">
    <div class="col-sm">
      <mat-form-field color="primary">
        <mat-label>ID Number</mat-label>
        <input matInput readonly type="text" value="{{patient?.identityNumber}}" />
      </mat-form-field>
      <mat-form-field color="primary" *ngIf="control.displayContactDetails">
        <mat-label>Tel</mat-label>
        <input matInput readonly type="text" value="{{patient?.tel}}"/>
      </mat-form-field>
      <mat-form-field color="primary" *ngIf="control.displayContactDetails">
        <mat-label>Cell</mat-label>
        <input matInput readonly type="text" value="{{patient?.cell}}" />
      </mat-form-field>
      <mat-form-field color="primary" *ngIf="control.displayContactDetails">
        <mat-label>Email</mat-label>
        <input matInput readonly type="text" value="{{patient?.email}}"/>
      </mat-form-field>
    </div>
    <div *ngIf="control.displayAddressDetails" class="col-sm">
      <mat-form-field color="primary">
        <mat-label>Address</mat-label>
        <textarea matInput readonly rows="6">{{patient?.address}}</textarea>
      </mat-form-field>
    </div>
    <div class="col-sm">
      <mat-form-field color="primary"  *ngIf="control.displayMedicalAidDetails">
        <mat-label>Scheme</mat-label>
        <input matInput readonly type="Text" value="{{patient?.medicalAid?.scheme}}" />
      </mat-form-field>
      <mat-form-field color="primary"  *ngIf="control.displayMedicalAidDetails">
        <mat-label>Mem No</mat-label>
        <input matInput readonly type="Text" value="{{patient?.medicalAid?.medicalAidNumber}}" />
      </mat-form-field>
      <mat-form-field color="primary"  *ngIf="control.displayMedicalAidDetails">
        <mat-label>Dep Code</mat-label>
        <input matInput readonly type="Text" value="{{patient?.medicalAid?.dependantCode}}" />
      </mat-form-field>
      <mat-form-field *ngIf="control.displayDoctorsDetails" color="primary">
        <mat-label>Referring Doctor</mat-label>
        <input matInput readonly type="Text" value="{{patient?.allDoctors}}" />
      </mat-form-field>
    </div>
  </div>
</div>
