<h2 mat-dialog-title>{{editing ? 'Edit' : 'Add'}} Medication Type</h2>
<mat-dialog-content>
  <form class="form" [formGroup]="form" *ngIf="form !== null" [class.form-validated]="validate">
    <mat-form-field appearance="fill" color="primary" class="w-100">
      <mat-label>Name</mat-label>
      <input matInput formControlName="description" type="text"/>
    </mat-form-field>
    <mat-form-field appearance="fill" color="primary" class="w-100">
      <mat-label>Comment</mat-label>
      <textarea matInput formControlName="comment" rows="3"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="mx-3 mb-2">
  <button mat-button (click)="close()">Cancel</button>
  <button mat-flat-button color="primary" (click)="save()">{{editing ? 'Update' : 'Save'}}</button>
</mat-dialog-actions>
