import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AppTabsService} from '../../../core/services/tabs.service';
import {QueryPatient} from '../../patient-repository/entities/patient';
import {Subject, Subscription} from 'rxjs';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { debounceTime } from 'rxjs/operators';
import {PatientRepositoryService} from '../../patient-repository/patient-repository.service';
import {Appointment} from '../../patient-appointments-repository/entities/appointment';

@Component({
  selector: 'app-flagged-patients-table',
  templateUrl: './flagged-patients-table.component.html',
  styleUrls: ['./flagged-patients-table.component.scss']
})
export class FlaggedPatientsTableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() patients: QueryPatient[] = [];
  dataSource = new MatTableDataSource<QueryPatient>([]);
  displayedColumns: string[] = ['status', 'profileId', 'name', 'cell', 'identityNumber', 'medicalAidNumber', 'reason', 'actions'];
  private sortedPatients: QueryPatient[];

  commentTextChanged = new Subject<QueryPatient>();
  private commentChangedSubscription: Subscription;
  private patientRepositorySubscription: Subscription;
  private debounceTime = 750;
  @ViewChild('flaggedPatientsTable') matTable: MatTable<QueryPatient>;

  constructor(private tabService: AppTabsService, private patientsRepository: PatientRepositoryService) { }

  ngOnInit(): void {
    this.commentChangedSubscription = this.commentTextChanged.pipe(debounceTime(this.debounceTime)).subscribe(patient => this.updatePatientComment(patient));
    this.sortedPatients = this.patients.sort((a, b) => {
      if (a.flaggedDate === b.flaggedDate) {
        return a.displayName.localeCompare(b.displayName);
      }
      return a.flaggedDate < b.flaggedDate ? 1 : -1;
    });
  }

  ngOnDestroy(): void {
    this.commentChangedSubscription?.unsubscribe();
    this.patientRepositorySubscription?.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.patients) {
      this.sortedPatients = this.patients.sort((a, b) => {
        if (a.flaggedDate === b.flaggedDate) {
          return a.displayName.localeCompare(b.displayName);
        }
        return a.flaggedDate < b.flaggedDate ? 1 : -1;
      });
      this.dataSource.data = [...this.sortedPatients];
    }
    this.matTable?.renderRows();
  }
  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource<QueryPatient>(this.sortedPatients);
  }

  view(patient: QueryPatient): void {
    this.tabService.openTabWithSearchPatient(patient);
  }

  private updatePatientComment(patient: QueryPatient) {
    this.patientRepositorySubscription = this.patientsRepository.updateFlaggedComment(patient.id, patient.flaggedComment).subscribe(result => {
    }, error => {
      console.error(error);
    });
  }

}

