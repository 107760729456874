import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {TreatmentPlanItem} from '../../../treatment-plans-repository/entities/treatment-plan-item';
import {TreatmentPlansRepositoryService} from '../../../treatment-plans-repository/treatment-plans-repository.service';
import {TextSanitizerPipe} from '../../../../shared/pipes/text-sanitizer.pipe';
import {PatientService} from '../../../patients/services/patient.service';
import {Patient} from '../../../patient-repository/entities/patient';
import {CoreService} from '../../../../core/services/core.service';
import {PatientEvent, PatientEventsService} from '../../../patients/services/patient-events.service';

@Component({
  selector: 'app-treatment-plan-action-item',
  templateUrl: './treatment-plan-action-item.component.html',
  styleUrls: ['./treatment-plan-action-item.component.scss']
})
export class TreatmentPlanActionItemComponent implements OnInit, OnDestroy {

  private treatmentPlanSubscription: Subscription;
  error = '';
  loading = false;
  @Input() item: TreatmentPlanItem;
  @Input() usePrimaryText = false;

  constructor(
    private coreService: CoreService,
    private patientService: PatientService,
    private patientEventService: PatientEventsService,
    private treatmentPlanItemsRepository: TreatmentPlansRepositoryService,
    private textSanitizePipe: TextSanitizerPipe) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.treatmentPlanSubscription) { this.treatmentPlanSubscription.unsubscribe(); }
  }



  get patientId(): number {
    return this.patientService.currentPatient.id;
  }

  get patient(): Patient {
    return this.patientService.currentPatient;
  }

  get confirmationMessage(): string {
    return 'Are you sure you want to remove ' + this.textSanitizePipe.transform(this.item.action.substr(0, 50))  + '... from ' + this.patient.firstName + ' ' + this.patient.surname + '?';
  }

  removeTreatmentPlanItemFromPatient(treatmentPlanItemId: number) {
    this.loading = true;
    this.error = '';
    this.treatmentPlanSubscription = this.treatmentPlanItemsRepository.removeTreatmentPlanItemFromPatient(this.patientId, treatmentPlanItemId).subscribe(() => {
      this.loading = false;
      this.patientEventService.emitEvent(new PatientEvent('treatment_plan_action_item_deleted'));
    }, error => {
      this.error = error;
      this.coreService.showErrorToast(error);
      this.loading = false;
    });
  }
}
