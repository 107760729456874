<h1>Scripts</h1>

<div class="mat-button-group mb-3"  *appPermissionRequired="'Clinical.CustomScripts.Write'; condition: !loading">
  <button mat-flat-button color="primary"  (click)="CreateCustomScript()">Create Acute Script</button>
</div>

<app-loading-spinner *ngIf="loading"></app-loading-spinner>

<div class="mat-card-list" *appPermissionRequired="'Clinical.Scripts.Read'; condition: !loading">
  <ng-container  *ngFor="let script of scripts">
    <mat-card class="compact">
      <app-script-widget [script]="script" ></app-script-widget>
    </mat-card>
  </ng-container>


  <mat-card *ngIf="!hasScripts">
    <mat-card-content>
      <span>No scripts have been printed for this patient.</span>
    </mat-card-content>
  </mat-card>
</div>
