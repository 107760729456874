<div class="updater">
  <app-loading-bar *ngIf="processing"></app-loading-bar>
  <div class="d-flex flex-column">
    <div  class="files-list d-flex flex-row align-items-center justify-content-between">
      <div class="d-flex flex-column flex-grow-1">
        <mat-form-field class="w-100">
          <mat-label>File Name</mat-label>
          <input matInput [(ngModel)]="upload.fileName" required>
        </mat-form-field>
      </div>
      <app-tag-search class="w-50 ms-3" [searchObservable]="searchTags" [(tags)]="fileTags" (tagsChange)="onTagsChange($event)"></app-tag-search>
    </div>
    <div class="total-upload-progress d-flex flex-row flex-nowrap align-items-center justify-content-end">
      <button mat-button color="primary" (click)="cancel()" [disabled]="processing">Cancel</button>
      <button mat-flat-button color="primary" (click)="update()" [disabled]="processing">Update</button>
    </div>
  </div>
</div>
