<div class="content d-flex flex-row align-items-center justify-content-between w-100">
  <h1 [style.color]="row.labelColour">{{row.title}}</h1>
  <div class="flex-spacer"></div>
  <mat-form-field appearance="fill" color="primary" class="compact" *ngIf="row.showDate">
    <mat-label>Report Date</mat-label>
    <app-mat-date-time-input [enableTime]="false" #dateTimeInputComponent  [(ngModel)]="value" (ngModelChange)="onValueChanges($event)"></app-mat-date-time-input>
    <button mat-icon-button matSuffix (click)="dateTimeInputComponent.togglePicker()"><mat-icon>today</mat-icon></button>
  </mat-form-field>
  <ng-container *ngIf="template.procedureId !== 0" >
    <div class="flex-spacer"></div>
    <mat-form-field appearance="fill" color="primary">
      <mat-label>Selected Procedure Entry</mat-label>
      <mat-select [(ngModel)]="template.procedureEntryId" (ngModelChange)="onProcedureEntryChange()">
        <mat-option *ngFor="let entry of entryOptions" [value]="entry.value">
          {{entry.text}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</div>
