<mat-card appearance="outlined">
  <mat-card-content>
    <table mat-table [dataSource]="dataSource" class="w-100" #flaggedPatientsTable>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" [style.background-color]="element.status.color"></td>
      </ng-container>
      <ng-container matColumnDef="profileId">
        <th mat-header-cell *matHeaderCellDef>File No</th>
        <td mat-cell *matCellDef="let element">{{element.profileId}}</td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name. </th>
        <td mat-cell *matCellDef="let element">{{element.formalName}}</td>
      </ng-container>
      <ng-container matColumnDef="cell">
        <th mat-header-cell *matHeaderCellDef class="d-responsive d-md"> Cell No </th>
        <td mat-cell *matCellDef="let element" class="d-responsive d-md">{{element.cell}}</td>
      </ng-container>
      <ng-container matColumnDef="identityNumber">
        <th mat-header-cell *matHeaderCellDef class="d-responsive d-lg"> ID No </th>
        <td mat-cell *matCellDef="let element" class="d-responsive d-lg">{{element.identityNumber}}</td>
      </ng-container>
      <ng-container matColumnDef="medicalAidNumber">
        <th mat-header-cell *matHeaderCellDef class="d-responsive d-lg"> Medical Aid No </th>
        <td mat-cell *matCellDef="let element" class="d-responsive d-lg">{{element.medicalAidNumber}}</td>
      </ng-container>
      <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef class="d-responsive d-xl"> Reason </th>
        <td mat-cell *matCellDef="let element" class="d-responsive d-xl">
          <mat-form-field appearance="fill" *appPermissionRequired="'Clinical.Patients.Write'; else: readonlyComment" subscriptSizing="dynamic" class="compact w-100">
            <mat-label>Reason</mat-label>
            <input matInput type="text" [(ngModel)]="element.flaggedComment" (ngModelChange)='commentTextChanged.next(element)'>
          </mat-form-field>
          <ng-template  #readonlyComment>
            <mat-form-field appearance="fill"  subscriptSizing="dynamic" class="w-100">
              <mat-label></mat-label>
              <input readonly matInput type="text" [ngModel] = "element.flaggedComment">
            </mat-form-field>
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          <div class="d-flex align-items-center justify-content-center">
          <button mat-icon-button *appPermissionRequired="'Clinical.Patients.Read'" (click)="view(element)">
            <mat-icon>open_in_new</mat-icon>
          </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
</mat-card>
