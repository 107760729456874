import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {AbstractControl, FormControl, UntypedFormGroup} from '@angular/forms';

@Component({
    selector: 'app-doctor-display-card',
    templateUrl: './doctor-display-card.component.html',
    styleUrls: ['./doctor-display-card.component.scss']
})
export class DoctorDisplayCardComponent implements OnInit {

    @Input() controlFormGroup: AbstractControl;
    @Input() index: number;
    @Output() delete: EventEmitter<number> = new EventEmitter<number>();
    formGroup: UntypedFormGroup;

    constructor() { }

    ngOnInit(): void {
      this.formGroup = this.controlFormGroup as UntypedFormGroup;
    }

    deleteItem() {
        this.delete.emit(this.index);
    }
}
