<div class="mb-3">
  <mat-label *ngIf="control.hasLabel" [style.color]="control.labelColour">{{control.label}}</mat-label>
  <mat-list class="mat-list-dynamic">
    <mat-list-item [style.color]="control.valueColour" *ngFor="let item of problemList;let i = index;">
      <span mat-line *ngIf="item.comment">{{i+1}} - {{item.problem}}</span>
      <span *ngIf="!item.comment">{{i+1}} - {{item.problem}}</span>
      <span mat-line *ngIf="item.comment">{{item.comment}}</span>
    </mat-list-item>
  </mat-list>
</div>
