import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DraftsService, PatientDraft} from 'src/app/core/services/drafts.service';
import {Email, EmailContact} from '../../patient-emails-repository/entities/email';
import {PatientEmailsRepositoryService} from '../../patient-emails-repository/patient-emails-repository.service';
import {PatientService} from '../../patients/services/patient.service';
import {CoreService} from '../../../core/services/core.service';
import {EmailStatus} from '../../patient-emails-repository/entities/email-status';
import {Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {EmailTemplate} from '../../email-template-repository/entities/email-template-entity';
import {Option} from '../../../shared/components/app-mat-select/app-mat-select.component';
import {PatientEvent, PatientEventsService} from '../../patients/services/patient-events.service';
import {DraftInteractionBehaviour} from '../../../core/layout/draft-overlay/draft-interaction-behaviour';

@Component({
  selector: 'app-create-email', templateUrl: './create-email.component.html', styleUrls: ['./create-email.component.scss']
})
export class CreateEmailComponent implements OnInit, OnDestroy, DraftInteractionBehaviour {

  @Input() draft: PatientDraft;
  form: UntypedFormGroup = null;
  email: Email = null;
  loading = false;
  saving = false;
  currentTemplate: EmailTemplate = null;
  templates: Option[] = [];
  private emailTemplates: EmailTemplate[] = [];
  private subscriptions: Subscription[] = [];
  private triggerTextChanges = false;
  private debounceTimeOut = 1500;

  constructor(
    private draftService: DraftsService,
    private formBuilder: UntypedFormBuilder,
    private patientService: PatientService,
    private patientEventService: PatientEventsService,
    private coreService: CoreService,
    private emailsRepository: PatientEmailsRepositoryService
  ) {
  }

  get saveButtonEnabled(): boolean {
    return this.formValid && !this.saving;
  }

  get formValid(): boolean {
    return this.form && this.form.valid;
  }

  get isEditing(): boolean {
    return this.draft.draftId !== null && this.email !== null;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      template: [''], to: ['', Validators.required], cc: [''], subject: ['', Validators.required], message: ['', Validators.required],
    });
    this.emailTemplates = this.coreService.emailTemplates;
    const templates = this.emailTemplates.map(template => ({value: template.id, text: template.name}));
    const empty = [{value: '', text: 'Please select a template'}];
    this.templates = [...empty, ...templates];
    this.getEmailDetails();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  save() {
    this.formChanged(true);
  }

  getEmailDetails() {
    this.loading = true;
    this.subscriptions.push(this.emailsRepository.load(this.draft.draftId).subscribe(email => {
      this.email = email;
      this.dataLoaded();
    }, error => {
      console.error(error);
    }));
  }

  private templateChanged(value: number | string): void {
    if (typeof value === 'string') {
      value = parseInt(value, 10);
    }
    const template = this.emailTemplates.find(t => t.id === value);
    this.form.get('message').setValue(template.template);
    this.currentTemplate = template;
    this.formChanged();
  }

  private dataLoaded(): void {
    this.handleExistingEmailDataLoaded();
    this.subscribeToFormChanges();
    this.loading = false;
  }

  private handleExistingEmailDataLoaded(): void {
    this.form.get('to').setValue(this.email.to.map(contact => contact.address).join(','));
    this.form.get('cc').setValue(this.email.cc.map(contact => contact.address).join(','));
    this.form.get('subject').setValue(this.email.subject);
    this.form.get('message').setValue(this.email.content);
    this.form.updateValueAndValidity();
    this.triggerTextChanges = true;
  }

  private subscribeToFormChanges(): void {
    this.subscriptions.push(this.form.get('template').valueChanges.subscribe((value) => {
      this.templateChanged(value);
    }));
    this.subscriptions.push(this.form.get('to').valueChanges.pipe(debounceTime(this.debounceTimeOut), distinctUntilChanged()).subscribe((value) => {
      this.formChanged();
    }));
    this.subscriptions.push(this.form.get('cc').valueChanges.pipe(debounceTime(this.debounceTimeOut), distinctUntilChanged()).subscribe((value) => {
      this.formChanged();
    }));
    this.subscriptions.push(this.form.get('subject').valueChanges.pipe(debounceTime(this.debounceTimeOut), distinctUntilChanged()).subscribe((value) => {
      this.formChanged();
    }));
    this.subscriptions.push(this.form.get('message').valueChanges.pipe(debounceTime(this.debounceTimeOut), distinctUntilChanged()).subscribe((value) => {
      if (!this.triggerTextChanges) {
        return;
      }
      this.formChanged();
    }));
  }

  private formChanged(locked: boolean = false) {
    if (this.saving) {
      return;
    }
    if (this.form.valid) {
      this.updateEmail(locked);
    }
  }

  private updateEmail(locked: boolean): void {
    const toValue = this.form.get('to').value;
    const ccValue = this.form.get('cc').value;
    this.email.to = toValue !== '' ? toValue.split(',').map(address => ({address: address, name: ''}) as EmailContact) : [];
    this.email.cc = ccValue !== '' ? ccValue.split(',').map(address => ({address: address, name: ''}) as EmailContact) : [];
    this.email.subject = this.form.get('subject').value;
    this.email.content = this.form.get('message').value;
    this.email.lastModifiedDate = new Date();
    this.email.lastModifiedUser = this.coreService.currentUser;
    this.email.status = locked ? EmailStatus.PENDING : EmailStatus.DRAFT;
    const updateEmail = {
      id: this.email.id, to: this.email.to, cc: this.email.cc, patientId: this.email.patientId, subject: this.email.subject, content: this.email.content, status: this.email.status
    } as Email;
    this.saving = true;
    if (locked) {
      this.updateAndSendEmail(updateEmail);
    } else {
      this.updateDraftEmail(updateEmail);
    }
  }

  private updateDraftEmail(updateEmail: Email) {
    this.subscriptions.push(this.emailsRepository.update(updateEmail).subscribe(() => {
      this.saving = false;
    }, error => {
      console.error(error);
    }));
  }

  private updateAndSendEmail(updateEmail: Email) {
    this.subscriptions.push(this.emailsRepository.send(updateEmail).subscribe(() => {
      this.saving = false;
      this.draftService.closeDraft(this.draft.index);
      this.patientEventService.emitEvent(new PatientEvent('emails_updated'));
    }, error => {
      console.error(error);
    }));
  }
}
