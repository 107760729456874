import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {Patient} from 'src/app/modules/patient-repository/entities/patient';
import {Script} from 'src/app/modules/scripts-repository/entities/script';
import {ScriptRepositoryService} from 'src/app/modules/scripts-repository/script-repository.service';
import {ActionDisabledComponent} from 'src/app/shared/components/action-disabled/action-disabled.component';
import {PatientService} from '../../patients/services/patient.service';
import {filter} from 'rxjs/operators';
import {ScriptType} from '../../scripts-repository/entities/script-type';
import {DraftsService} from '../../../core/services/drafts.service';
import {WebsocketService} from '../../../core/services/websocket.service';
import {PatientEvent, PatientEventsService} from '../../patients/services/patient-events.service';

@Component({
  selector: 'patient-scripts-widget',
  templateUrl: './scripts-widget.component.html',
  styleUrls: ['./scripts-widget.component.scss']
})
export class ScriptsWidgetComponent implements OnInit, OnDestroy {

  error = '';
  loading = false;
  scripts: Script[] = [];
  @ViewChild(ActionDisabledComponent) disabledDialog: ActionDisabledComponent;
  private subscriptions: Subscription[] = [];
  private channelName = '';
  private readonly eventName = 'scripts_changed';

  constructor(private patientService: PatientService, private patientEventService: PatientEventsService, private scriptsRepository: ScriptRepositoryService, private draftService: DraftsService, private pushService: WebsocketService) {
  }

  get hasError(): boolean {
    return this.error.length > 0;
  }

  get hasScripts(): boolean {
    return this.scripts.length > 0;
  }

  get patient(): Patient {
    return this.patientService.currentPatient;
  }

  get patientId(): number {
    return this.patientService.currentPatient.id;
  }

  ngOnInit(): void {
    this.channelName = this.patientService.currentPatientEventsChannelName;
    this.subscriptions.push(this.patientService.patientScripts$.subscribe(scripts => {
      this.scripts = [...scripts];
    }));
    this.subscriptions.push(this.patientEventService.currentPatient$.subscribe(() => this.loadPatientScripts()));
    this.subscriptions.push(this.patientEventService.events$.pipe(filter(event => event.type === 'scripts_updated')).subscribe(() => this.loadPatientScripts()));
    this.pushService.subscribe(this.channelName, this.eventName, _ => {
      this.loadPatientScripts();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.pushService.unsubscribe(this.channelName, this.eventName);
  }

  CreateCustomScript() {
    this.scriptsRepository.saveScript(this.patientId, [], ScriptType.ACUTE).subscribe(saved => {
      this.draftService.addNewDraft(this.patient.id, this.patient.profileId, this.patient.practiceId, 'script', saved, 'New Script');
    }, error => {
      console.error(error);
    });
  }

  private loadPatientScripts() {
    if (!this.patientService.hasPatientDetails) {
      return;
    }
    this.error = '';
    this.patientService.loadPatientScripts();
  }
}
