<h1>Treatment Plans</h1>

<div class="mat-card-list" *appPermissionRequired="'Clinical.TreatmentPlans.Read';">
  <ng-container  *ngFor="let plan of plans">
    <mat-card class="compact">
      <app-treatment-plan-widget [plan]="plan"></app-treatment-plan-widget>
    </mat-card>
  </ng-container>


  <mat-card *ngIf="!hasPlans">
    <mat-card-content>
      <span>No treatment plans have been printed for this patient.</span>
    </mat-card-content>
  </mat-card>
</div>
