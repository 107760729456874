import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Upload} from '../../upload-repository/entities/upload';
import {FileUploaderService} from '../file-uploader.service';

@Component({
  exportAs: 'appFileDownloader',
  selector: 'app-file-downloader',
  templateUrl: './file-downloader.component.html',
  styleUrls: ['./file-downloader.component.scss']
})
export class FileDownloaderComponent implements OnInit {

  @Output() processing = new EventEmitter<boolean>();
  @Input() upload: Upload;
  @ViewChild('fileLink') fileLink: ElementRef;
  private fileUrl = '';

  constructor(private fileUploadService: FileUploaderService) {
  }

  get url(): string {
    return this.fileUrl;
  }

  ngOnInit(): void {
  }

  openAttachment() {
    this.processing.next(true);
    this.fileUploadService.getSignedUrl(this.upload.path, 'GET', 300).then(url => {
      this.fileUrl = url;
      // angular workaround to open the link once the view has updated with the new url value
      // TODO: Look into having in app preview rather then this hacky methods
      setTimeout(() => {
        this.processing.next(false);
        this.fileLink.nativeElement.click();
      }, 1);
    }).catch(error => {
      this.processing.next(false);
      console.error(error);
    });
  }

}
