<app-loading-bar *ngIf="loading || saving"></app-loading-bar>
<form class="form d-flex flex-column" [formGroup]="form">
    <div class="row">
        <div class="col-sm-12">
          <mat-form-field appearance="fill" color="primary" class="w-100">
            <mat-label>To</mat-label>
            <input matInput type="text" formControlName="to"/>
          </mat-form-field>
        </div>
        <div class="col-sm-12">
          <mat-form-field appearance="fill" color="primary" class="w-100">
            <mat-label>Subject</mat-label>
            <input matInput type="text" formControlName="subject"/>
          </mat-form-field>
        </div>
    </div>
  <div class="row flex-grow-1">
    <div class="col-sm-12">
      <mat-form-field appearance="fill" color="primary" class="w-100">
        <mat-label>Email Template</mat-label>
        <mat-select formControlName="template">
          <mat-option *ngFor="let template of templates" [value]="template.value">{{template.text}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-sm-12">
      <mat-form-field appearance="fill" color="primary" class="w-100">
        <mat-label>Message</mat-label>
        <textarea matInput type="text" formControlName="message" [rows]="10"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="isEditing">
    <div class="col-sm-12 d-flex flex-row justify-content-between align-items-center">
      <span class="mat-card-subtitle">{{email.lastModifiedDate | date:'yyyy-MM-dd HH:mm'}}</span>
      <span class="mat-card-subtitle">{{email.lastModifiedUser.firstName}} {{email.lastModifiedUser.surname}}</span>
    </div>
  </div>
</form>
