<mat-card-content>
  <mat-card-title class="d-flex flex-row align-items-center mat-bold">
    <span>{{script.scriptDate | date:'yyyy-MM-dd'}}</span>
    <span class="script ms-3"> {{script.scriptType | scriptTypeFormatter}}</span>
    <div class="flex-spacer"></div>
    <button mat-icon-button  *appPermissionRequired="'Clinical.Documents.Write'" (click)="print(script.id)" [disabled]="printing"><mat-icon>print</mat-icon></button>
  </mat-card-title>
  <mat-card-subtitle class="d-flex flex-row justify-content-between">
    <span>{{script.lastModifiedDate | date:'yyyy-MM-dd HH:mm'}}</span>
    <span>{{script.lastModifiedUser.firstName}} {{script.lastModifiedUser.surname}}</span>
  </mat-card-subtitle>
</mat-card-content>
<mat-divider></mat-divider>
