import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-file-icon',
  templateUrl: './file-icon.component.html',
  styleUrls: ['./file-icon.component.scss']
})
export class FileIconComponent {

  @Input() extension: string;
  @Input() size: size = 'default';
  @Input() grayscale = false;
  constructor() { }

  get sizeClass(): string {
    switch (this.size) {
      case 'extra-small':
         return 'file-icon-xs';
      case 'small':
        return 'file-icon-sm';
      case 'medium':
        return 'file-icon-md';
      case 'large':
        return 'file-icon-lg';
      case 'extra-large':
        return 'file-icon-xl';
      default:
        return '';
    }
  }
}

export type size =
  | 'extra-small'
  | 'small'
  | 'medium'
  | 'default'
  | 'large'
  | 'extra-large';
