<app-loading-bar *ngIf="loading || saving"></app-loading-bar>
<form [formGroup]="form" class="form d-flex flex-column">
  <div class="row">
    <div class="col-sm-12">
      <mat-form-field appearance="fill" color="primary" class="w-100">
        <mat-label>To</mat-label>
        <input matInput type="text" formControlName="to"/>
      </mat-form-field>
    </div>
  </div>
  <div class="row flex-grow-1">
    <div class="col-sm-12">
      <mat-form-field appearance="fill" color="primary" class="w-100">
        <mat-label>Message</mat-label>
        <textarea matInput type="text" formControlName="message" [rows]="10"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="isEditing">
    <div class="col-sm-12 d-flex flex-row justify-content-between align-items-center">
      <span class="mat-card-subtitle">{{message.lastModifiedDate | date:'yyyy-MM-dd HH:mm'}}</span>
      <span class="mat-card-subtitle">{{message.lastModifiedUser.firstName}} {{message.lastModifiedUser.surname}}</span>
    </div>
  </div>
</form>

