<h1>Treatment Plan Listing</h1>

<app-mat-autocomplete placeholder="Search Plans" [alwaysShowAddCard]="true" [searchObservable]="searchTreatmentPlanItems$" (addNewItem)='onAddNewItem()'  [addItemCardText]="'Add new plan item.'"
                        [displayResultsCardTemplate]='displayResultsCardTemplate'  *appPermissionRequired="'Clinical.TreatmentPlansListing.Write'"></app-mat-autocomplete>
<ng-template #displayResultsCardTemplate let-result="result" let-queryText="queryText">
  <div class="d-flex flex-row justify-content-between align-items-start">
    <span [innerHtml]="result.action | textHighlight: queryText"></span>
    <button mat-icon-button (click)="onItemSelected(result)"><mat-icon>add</mat-icon></button>
  </div>
  <mat-form-field color="primary" appearance="fill" class="compact w-100" subscriptSizing="dynamic">
    <mat-label>Comments/Notes</mat-label>
    <input matInput type="text" [(ngModel)]="result.comment"/>
  </mat-form-field>
</ng-template>

<app-loading-spinner *ngIf="loading"></app-loading-spinner>

<div class="mat-card-list" *appPermissionRequired="'Clinical.TreatmentPlansListing.Read';">
  <ng-container  *ngFor="let item of actions;">
    <mat-card class="compact">
      <app-treatment-plan-action-item [item]="item" [usePrimaryText]="settings.themeWidgetText" class="w-100"></app-treatment-plan-action-item>
    </mat-card>
  </ng-container>


  <mat-card *ngIf="!hasItems">
    <mat-card-content>
      <span>No treatment plan actions have been added to this patient.</span>
    </mat-card-content>
  </mat-card>
</div>

<button mat-flat-button color="primary" class="w-100" *appPermissionRequired="'Clinical.TreatmentPlans.Write'; condition: hasItems" appConfirmationDialog [confirmMessage]="confirmationMessage" (confirm)="savePlan()">Save Plan</button>
