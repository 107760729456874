import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Doctor, Patient, QueryPatient} from 'src/app/modules/patient-repository/entities/patient';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Option} from 'src/app/shared/components/app-mat-select/app-mat-select.component';
import {DoctorAddCardComponent} from '../doctor-add-card/doctor-add-card.component';
import {PatientService} from '../../patients/services/patient.service';
import {PatientFormService} from './patient-form.service';
import {AuthenticationService} from 'src/app/core/auth/authentication.service';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import {DoctorRepositoryService} from '../../doctor-repository/doctor-repository.service';
import {CoreService} from '../../../core/services/core.service';
import {MedicalScheme} from '../../medical-schemes-repository/entities/medical-scheme';
import {PatientRepositoryService} from '../../patient-repository/patient-repository.service';
import {AppTabsService} from '../../../core/services/tabs.service';
import {PatientStatus} from '../../patient-repository/entities/patient-status';
import {MatDialog} from '@angular/material/dialog';
import {AppMatAutocompleteComponent} from '../../../shared/components/app-mat-autocomplete/app-mat-autocomplete.component';
import {PatientEventsService} from '../../patients/services/patient-events.service';
import {DatePipe} from '@angular/common';

const oldDate = new Date(new Date().setFullYear(new Date().getFullYear() - 100));
const today = new Date();

@Component({
  exportAs: 'appPatientForm',
  selector: 'app-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.scss']
})
export class PatientFormComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup = null;
  validate = false;
  showFullErrorMessage = false;
  imgSrc = '/assets/img/default-avatar.png';
  languages: Option[] = [];
  gender: Option[] = [];
  races: Option[] = [];
  titles: Option[] = [];
  duplicates: QueryPatient[] = [];
  duplicatesOpen = false;
  statuses: PatientStatus[] = [];
  @Input() patient: Patient;
  @Output() patientNameChange: EventEmitter<string> = new EventEmitter();
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('appMatAutocompleteComponent') doctorsAutocomplete: AppMatAutocompleteComponent;
  private subscriptions: Subscription[] = [];
  private fileKey: string;
  private file: File;
  private debounceTimeOut = 1500;
  private debounceTimeOutShort = 100;
  private searching = false;

  constructor(
    private matDialog: MatDialog,
    private datePipe: DatePipe,
    private patientsService: PatientService,
    private patientFormService: PatientFormService,
    private patientEventService: PatientEventsService,
    private doctorsRepository: DoctorRepositoryService,
    private authService: AuthenticationService,
    private coreService: CoreService,
    private patientsRepository: PatientRepositoryService,
    private tabService: AppTabsService) {
  }

  get hasTabs(): boolean {
    return this.tabService.hasTabs();
  }

  get isEditForm(): boolean {
    return !!this.patient;
  }

  get minDobValue(): Date {
    return oldDate;
  }

  get maxDobValue(): Date {
    return today;
  }

  get formValid(): boolean {
    if (!this.form) {
      return false;
    }
    return this.form.valid || false;
  }

  get validationEnabled(): boolean {
    return this.validate;
  }

  get addressDetails(): UntypedFormArray {
    return this.form.get('addressDetails') as UntypedFormArray;
  }

  get emailAddresses(): UntypedFormArray {
    return this.form.get('emailAddresses') as UntypedFormArray;
  }

  get contactNumbers(): UntypedFormArray {
    return this.form.get('contactNumbers') as UntypedFormArray;
  }

  get secondaryContacts(): UntypedFormArray {
    return this.form.get('secondaryContacts') as UntypedFormArray;
  }

  get familyDoctors(): UntypedFormArray {
    return this.form.get('familyDoctors') as UntypedFormArray;
  }

  get referringDoctors(): UntypedFormArray {
    return this.form.get('referringDoctors') as UntypedFormArray;
  }

  get billingContact(): UntypedFormGroup {
    return this.form.get('billingContact') as UntypedFormGroup;
  }

  get billingContactAddressDetails(): UntypedFormArray {
    return this.billingContact.get('billingContactAddressDetails') as UntypedFormArray;
  }

  get billingContactContactNumbers(): UntypedFormArray {
    return this.billingContact.get('billingContactContactNumbers') as UntypedFormArray;
  }

  get billingContactEmailAddresses(): UntypedFormArray {
    return this.billingContact.get('billingContactEmailAddresses') as UntypedFormArray;
  }

  get medicalAid(): UntypedFormGroup {
    return this.form.get('medicalAid') as UntypedFormGroup;
  }

  get urlName(): string {
    return this.patient.name.replace(/\([^)]*\)/g, '').replace(/\s\s+/g, ' ').split(' ').join('-');
  }

  get backButtonRoute(): string {
    if (this.isEditForm) {
      return `/patients/${this.patient.profileId}/${this.urlName}`;
    } else {
      return '/dashboard';
    }
  }

  get hasDuplicates(): boolean {
    return this.duplicates.length > 0;
  }

  get hasScheme(): boolean {
    return this.patientFormService.hasScheme;
  }

  get fullFormError(): any {
    return this.patientFormService.printAllFormErrors();
  }

  ngOnInit(): void {
    this.subscriptions.push(this.patientEventService.events$.subscribe(event => {
      if (this.isEditForm && event.type === 'trigger_save') {
        this.save();
      }
      if (this.isEditForm && event.type === 'patient_status_updated') {
        this.updateStatus(event.data);
      }
    }));
    this.languages = this.coreService.patientLanguages.map(language => ({value: language.code, text: language.name}));
    this.gender = this.coreService.patientGenders.map(gender => ({value: gender.code, text: gender.name}));
    this.races = this.coreService.patientRaces.map(race => ({value: race.code, text: race.name}));
    this.titles = this.coreService.patientTitles.map(title => ({value: title.code, text: title.name}));
    this.statuses = this.coreService.patientStatuses.sort((a, b) => a.id > b.id ? 1 : -1);
    this.form = this.patientFormService.new(this.patient);
    this.getImgSrc();
    this.formEventHandler();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.patientFormService.reset();
  }

  toggleFullErrorMessage() {
    this.showFullErrorMessage = !this.showFullErrorMessage;
  }

  getImgSrc() {
    if (this.patient && this.patient?.profilePictureUrl?.length !== 0) {
      this.patientsService.getUploadUrl(this.patient.profilePictureUrl, 'GET', 3600).then(url => {
        this.imgSrc = url;
      }).catch(error => {
        console.error(error);
      });
    }
  }

  save() {
    if (this.formValid) {
      if (this.fileKey && this.fileKey.length) {
        this.uploadImage();
      } else {
        this.savePatient();
      }
    }
    this.validate = true;
    this.patientFormService.validateAllFormFields();
  }

  setBillingDetailsToPatientDetails() {
    this.form.get('billingContact').get('billingContactFirstName').patchValue(this.form.get('firstName').value);
    this.form.get('billingContact').get('billingContactSurname').patchValue(this.form.get('surname').value);
    this.form.get('billingContact').get('billingContactIdentityNumber').patchValue(this.form.get('identityNumber').value);
    this.form.get('billingContact').get('billingContactIdentityNumberConfirmation').patchValue(this.form.get('identityNumberConfirmation').value);
    this.form.get('billingContact').get('billingContactContactNumbers').patchValue(this.contactNumbers.value);
    this.form.get('billingContact').get('billingContactAddressDetails').setValue(this.addressDetails.value);
    this.form.get('billingContact').get('billingContactEmailAddresses').setValue(this.emailAddresses.value);
  }

  toggleIsOpen() {
    this.duplicatesOpen = !this.duplicatesOpen;
  }

  openFileBrowser() {
    this.fileInput.nativeElement.click();
  }

  onFileChanged(event: { target: { files: File[]; }; }) {
    // Select File
    this.file = event.target.files[0];
    const ext = this.file.name.split('.')[1];
    const name = AuthenticationService.uuidv4() + '.' + ext;
    this.fileKey = 'uploads/patients/images/' + encodeURI(name);
    this.form.get('profilePictureUrl').setValue(this.fileKey);
    this.form.get('profilePictureUrl').updateValueAndValidity();
    this.setImageFromFileHandler();
  }

  uploadImage() {
    this.patientsService.getUploadUrl(this.fileKey, 'PUT', 3600).then(url => {
      this.patientsService.uploadFile(this.file, url).then(result => {
        this.savePatient();
      }).catch(error => {
        console.error(error);
      });
    }).catch(error => {
      console.error(error);
    });
  }

  updateStatus(id: number) {
    this.form.get('status').setValue(id);
    this.patient.status = this.statuses.find(s => s.id === id);
  }

  editPatient(index: number) {
    this.form.reset();
    const patient = this.duplicates[index];
    this.tabService.openTabWithPatientInEditMode(patient);
  }

  removeFamilyDoctor(index: number) {
    this.patientFormService.removeFamilyDoctor(index);
  }

  removeReferringDoctor(index: number) {
    this.patientFormService.removeReferringDoctor(index);
  }

  addAddress() {
    this.patientFormService.addAddress();
  }

  removeAddress(index: number) {
    this.patientFormService.removeAddress(index);
  }

  addEmailAddress() {
    this.patientFormService.addEmailAddress();
  }

  removeEmailAddress(index: number) {
    this.patientFormService.removeEmailAddress(index);
  }

  addContactDetail() {
    this.patientFormService.addContactNumber();
  }

  removeContactDetail(index: number) {
    this.patientFormService.removeContactNumber(index);
  }

  addSecondaryContactDetail() {
    this.patientFormService.addSecondaryContactPerson();
  }

  removeSecondaryContactDetail(index: number) {
    this.patientFormService.removeSecondaryContactPerson(index);
  }

  addBillingContactContactDetail() {
    this.patientFormService.addBillingContactContactNumber();
  }

  removeBillingContactContactDetail(index: number) {
    this.patientFormService.removeBillingContactContactNumber(index);
  }

  addBillingContactEmailAddress() {
    this.patientFormService.addBillingContactEmailAddress();
  }

  removeBillingContactEmailAddress(index: number) {
    this.patientFormService.removeBillingContactEmailAddress(index);
  }

  addBillingContactAddress() {
    this.patientFormService.addBillingContactAddress();
  }

  removeBillingContactAddress(index: number) {
    this.patientFormService.removeBillingContactAddress(index);
  }

  searchDoctors = (query: string): Observable<Doctor[]> => {
    return this.doctorsRepository.query(query.toLowerCase())
      .pipe(map(response => response.map(doctor => doctor)));
  }

  familyDoctorSelected(doctor: Doctor) {
    this.patientFormService.addFamilyDoctor(doctor);
  }

  referringDoctorSelected(doctor: Doctor) {
    this.patientFormService.addReferringDoctor(doctor);
  }

  addNewDoctor() {
    const matDialogRef = this.matDialog.open(DoctorAddCardComponent, {
      panelClass: 'mat-dialog-responsive'
    });
    matDialogRef.afterClosed().subscribe(doctor => {
      if (doctor) {
        this.doctorsAutocomplete.search(doctor.name);
      }
    });
  }

  setMedicalScheme(scheme: any) {
    this.form.get('medicalAid').get('scheme').setValue(scheme.name);
    this.form.get('medicalAid').get('destinationCode').setValue(scheme.destinationCode);
    if (this.isEditForm) {
      this.patient.medicalAid.scheme = scheme.name;
    }
  }

  clearMedicalScheme() {
    this.form.get('medicalAid').get('scheme').setValue('');
    this.form.get('medicalAid').get('destinationCode').setValue('');
    if (this.isEditForm) {
      this.patient.medicalAid.scheme = '';
    }
  }

  searchSchemes = (query: string): Observable<MedicalScheme[]> => {
    return this.patientsService.searchSchemes(query)
      .pipe(map(response => response.map(scheme => scheme)));

  }

  private savePatient() {
    if (this.isEditForm) {
      this.patientsService.updateCurrentPatient(this.form.value);
    } else {
      this.patientsService.createPatient(this.form.value);
    }
  }

  private formEventHandler() {
    this.subscriptions.push(this.form.get('firstName').valueChanges.pipe(debounceTime(this.debounceTimeOut), distinctUntilChanged()).subscribe(() => {
      this.formValueChangeHandler();
    }));
    this.subscriptions.push(this.form.get('surname').valueChanges.pipe(debounceTime(this.debounceTimeOut), distinctUntilChanged()).subscribe(() => {
      this.formValueChangeHandler();
    }));
    this.subscriptions.push(this.form.get('identityNumber').valueChanges.pipe(debounceTime(this.debounceTimeOutShort), distinctUntilChanged()).subscribe(() => {
      this.idControlStatusChangedHandler();
    }));
    const specificFields = ['firstName', 'surname', 'identityNumber', 'passportNumber', 'medicalAid', 'contactNumbers', 'emailAddresses', 'dateOfBirth'];
    this.subscriptions.push(this.form.valueChanges.pipe(filter(value => {
      // Check if any of the specific fields have changed
      return specificFields.some(field => this.form.get(field).dirty);
    })).pipe(debounceTime(this.debounceTimeOut), distinctUntilChanged()).subscribe(value => {
      this.duplicateChecker();
    }));
  }

  private setImageFromFileHandler() {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      const target = e.target as any;
      this.imgSrc = target.result;
    };
    reader.readAsDataURL(this.file);
  }

  private idControlStatusChangedHandler() {
    const idControl = this.form.get('identityNumber') as UntypedFormControl;
    if (idControl.valid && idControl.value && idControl.value.length > 5) {
      const identityValueString = idControl.value;
      let dobString = identityValueString.substr(0, 2) + '-' + identityValueString.substr(2, 2) + '-' + identityValueString.substr(4, 2);
      const year = dobString.split('-')[0];
      const currentYear = new Date().getFullYear().toString().slice(-2);
      const genderCode = identityValueString.substr(6, 1);
      const gender = parseInt(genderCode, 10) < 5 ? 'F' : 'M';
      const dobControl = this.form.get('dateOfBirth') as UntypedFormControl;
      const genderControl = this.form.get('gender') as UntypedFormControl;
      dobString = year < currentYear ? '20' + dobString : 19 + dobString;
      dobControl.setValue(dobString);
      dobControl.updateValueAndValidity();
      genderControl.setValue(gender);
      genderControl.updateValueAndValidity();
    }
  }

  private formValueChangeHandler() {
    const firstName = this.form.get('firstName').value || '';
    const surname = this.form.get('surname').value || '';
    const name = firstName + ' ' + surname;
    if (name === ' ') {
      return;
    }
    this.patientNameChange.emit(name);
  }

  private duplicateChecker() {
    if (this.isEditForm) {
      return;
    }
    if (this.searching) {
      return;
    }
    const queryParams = [];
    if (this.form.get('firstName').value) {
      queryParams.push('firstName=' + this.form.get('firstName').value);
    }
    if (this.form.get('surname').value) {
      queryParams.push('surname=' + this.form.get('surname').value);
    }
    if (this.form.get('dateOfBirth').value) {
      const dobString = this.datePipe.transform(this.form.get('dateOfBirth').value, 'yyyy-MM-dd 00:00:00');
      queryParams.push('dateOfBirth=' + dobString);
    }
    if (this.form.get('identityNumber').value) {
      queryParams.push('identityNumber=' + this.form.get('identityNumber').value);
    }
    if (this.form.get('passportNumber').value) {
      queryParams.push('passportNumber=' + this.form.get('passportNumber').value);
    }
    if (this.form.get('medicalAid').get('medicalAidNumber').value) {
      queryParams.push('medicalAid.medicalAidNumber=' + this.form.get('medicalAid').get('medicalAidNumber').value || '');
    }
    if (this.form.get('medicalAid').get('dependantCode').value) {
      queryParams.push('medicalAid.dependantCode=' + this.form.get('medicalAid').get('dependantCode').value || '');
    }
    if (this.contactNumbers.controls.length) {
      this.contactNumbers.controls.forEach(control => {
        if (control.get('number').value) {
          queryParams.push('contactNumbers.' + control.get('label').value + '=' + control.get('number').value.replace(/\s/g, ''));
        }
      });
    }
    if (this.emailAddresses.controls.length) {
      this.emailAddresses.controls.forEach(control => {
        if (control.get('emailAddress').value) {
          queryParams.push('emailAddresses.' + control.get('label').value + '=' + control.get('emailAddress').value);
        }
      });
    }
    const query = queryParams.filter(param => param.length > 0).join(',');
    console.log( query);
    if (query === '') {
      this.duplicates = [];
      return;
    }
    this.searching = true;
    this.subscriptions.push(this.patientsRepository.findSimilar(query).subscribe(results => {
      this.duplicates = results;
      if (this.duplicates.length < 5) {
        this.duplicatesOpen = true;
      }
      this.searching = false;
    }, error => {
      console.error(error);
      this.searching = false;
    }));
  }
}
