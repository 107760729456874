import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {ReportControl} from '../../report-builder/entities/report-control';

@Component({
    selector: 'app-report-control-editor',
    templateUrl: './report-control-editor.component.html',
    styleUrls: ['./report-control-editor.component.scss']
})
export class ReportControlEditorComponent implements OnInit {

    @Input() patientId: number;
    @Input() control: ReportControl;
    @Output() deleteControl = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

    delete() {
        this.deleteControl.emit();
    }
}
