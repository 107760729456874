import {Injectable} from '@angular/core';
import {Doctor} from './entities/doctor';
import {DoctorCollectionResponse} from './entities/doctor-collection-response';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DoctorItemResponse} from './entities/doctor-item-response';
import {DoctorPaginationResponse} from './entities/doctor-pagination-response';
import {ApiService} from '../base/repository/api.service';
import {AdminApiService} from '../base/repository/admin-api.service';
import {GenericPaginationResponse} from '../base/entities/generic-pagination-response';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';
import {ItemDeletedResponse} from '../base/entities/item-deleted-response';

@Injectable({
  providedIn: 'root'
})
export class DoctorRepositoryService {

  constructor(private api: ApiService, private adminApi: AdminApiService) {

  }

  query(query: string): Observable<Doctor[]> {
    return this.api.get<DoctorCollectionResponse>('/doctors/search/', { query: query })
      .pipe(map(response => response.items));
  }

  addDoctor(doctor: Doctor): Observable<number> {
    return this.api.post<ItemCreatedResponse>('/doctors/', doctor)
      .pipe(map(response => response.value));
  }

  getDoctor(id: number): Observable<Doctor> {
    return this.api.get<DoctorItemResponse>('/doctors/' + id)
      .pipe(map(response => response.value));
  }

  adminPaginate(type: string, pageNumber: number, pageSize: number, filter: string): Observable<DoctorPaginationResponse> {
    return this.adminApi.get<GenericPaginationResponse<DoctorPaginationResponse>>('/doctors/' + type, { pageNumber: pageNumber, pageSize: pageSize, filter: filter })
      .pipe(map(response => response.value));
  }

  adminAddDoctor(doctor: Doctor): Observable<number> {
    return this.adminApi.post<ItemCreatedResponse>('/doctors/', doctor)
      .pipe(map(response => response.value));
  }

  adminUpdateDoctor(id: number, updatedDoctor: Doctor): Observable<boolean> {
    return this.adminApi.put<ItemUpdatedResponse>('/doctors/' + id, updatedDoctor)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  adminDeleteDoctor(id: number): Observable<boolean> {
    return this.adminApi.delete<ItemDeletedResponse>('/doctors/' + id)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }
}
