<div class="mb-3" [formGroup]="form">
  <div class="row">
    <div class="col-sm-12 col-md-6 d-flex flex-row align-items-center justify-content-between">
      <h3 class="my-2">Secondary Contact</h3>
      <button mat-icon-button *ngIf="showDelete" (click)="deleteItem()"><mat-icon>delete</mat-icon></button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <mat-form-field appearance="fill" color="primary" class="w-100">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" />
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <mat-form-field appearance="fill" color="primary" class="w-100">
        <mat-label>Surname</mat-label>
        <input matInput formControlName="surname" />
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <mat-form-field class="w-100" appearance="fill" color="primary">
        <mat-label>Relation To Patient</mat-label>
        <input type="text"
               placeholder="Pick one"
               matInput
               formControlName="relationToPatient"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of relations" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <h4 class="mb-3">Contact Details</h4>
      <ng-container *ngFor="let contactNumberFormGroup of contactNumbers.controls; let i=index">
        <patient-contact-form [controlFormGroup]="contactNumberFormGroup" [index]="i" (delete)="removeContactDetail($event)"
                              [deleteEnabled]="contactNumbers.controls.length > 1"></patient-contact-form>
      </ng-container>
      <div class="row">
        <div class="col-12 col-md-6">
          <button mat-button color="primary" class="w-100" (click)="addContactDetail()">Add Phone Number</button>
        </div>
      </div>
    </div>
  </div>
  <mat-divider inset></mat-divider>
</div>
